import React, { useEffect, useState } from 'react';
import Papa from 'papaparse';
import { getDatabase, ref, onValue, off } from 'firebase/database';
import { useAuth } from 'hooks/useAuth';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { getFirebaseBackend } from 'helpers/firebaseHelper';
import _ from 'lodash';
import axios from 'axios';
import { MetaTags } from 'react-meta-tags';

import { GuidelineLayout } from './styles';
import { useTranslation } from 'react-i18next';
import ContentCard from './components/Content-Card';
import Breadcrumbs from '../../components/Breadcrumb';
import SendCard from './components/Send-Card';
import ButtonUploadCSV from './components/Button-Upload-CSV';
import {
  guidelineDeliveryTypeOptions,
  guidelineTypeOptions,
  languageOptions,
  monthNumberLabel,
} from '../../constants';
import CardAssignedTo from './components/Card-Assigned-To';
import PageLoading from './components/Page-Loading';

const formConfig = {
  defaultValues: {
    guidelines: [
      {
        deliveryType: null,
        reviewLink: '',
        type: null,
        language: '',
        totalSize: 100,
        primaryKeyword: '',
        searchVolume: '',
        secondaryKeywords: '',
        productionDate: {
          month: String(new Date().getMonth() + 1),
          year: String(new Date().getFullYear()),
        },
        deliveryDate: '',
        publishedDate: '',
      },
    ],
    assignedTo: [],
    notification: false,
  },
  shouldFocusError: true,
  criteriaMode: 'all',
  mode: 'onSubmit',
  reValidateMode: 'onChange',
};

const CreateGuideline = () => {
  const firebaseHelper = getFirebaseBackend();

  const form = useForm(formConfig);
  const { control, watch, setValue, handleSubmit } = form;
  const navigate = useNavigate();
  const { user } = useAuth();
  const currentAccountId = user?.account;
  const [teamData, setTeamData] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isGuidelineCreated, setIsGuidelineCreated] = useState(false);
  const [guidelineIds, setGuidelineIds] = useState([]);
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: 'guidelines',
  });

  const { t: translate } = useTranslation();

  const onSubmit = async (data) => {
    setIsSending(true);

    const firebaseToken = await firebaseHelper.getIdToken();
    const processedData = _.cloneDeep(data);

    processedData.guidelines = processedData.guidelines.map((guideline) => {
      return {
        ...guideline,
        deliveryType: guideline.deliveryType.value,
        reviewLink: guideline.reviewLink,
        type: guideline.type.value,
        language: guideline.language.value,
        primaryKeyword: guideline.primaryKeyword.toLowerCase(),
        secondaryKeywords: guideline.secondaryKeywords || guideline.secondaryKeywords.toLowerCase(),
        productionDate: {
          month: guideline.productionDate.month.value
            ? Number(guideline.productionDate.month.value)
            : Number(guideline.productionDate.month),
          year: guideline.productionDate.year.value
            ? Number(guideline.productionDate.year.value)
            : Number(guideline.productionDate.year),
        },
        deliveryDate: guideline.deliveryDate,
        publishedDate: guideline.publishedDate,
      };
    });
    processedData.assignedTo = processedData.assignedTo.map((member) => member.value);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/createNewGuideline`,
        {
          ...processedData,
          accountId: currentAccountId,
          development: process.env.REACT_APP_BUILD_TYPE !== 'production',
        },
        {
          headers: {
            Authorization: `Bearer ${firebaseToken}`,
          },
        },
      );
      setGuidelineIds(response.data?.guidelineIds ?? []);
      setIsSending(false);
      setIsPageLoading(true);
    } catch (error) {
      setIsSending(false);
      console.log(error);
    }
  };

  const handleAddComponent = () => {
    append({
      deliveryType: null,
      reviewLink: '',
      type: null,
      language: '',
      totalSize: 100,
      primaryKeyword: '',
      searchVolume: '',
      secondaryKeywords: '',
      productionDate: {
        month: String(new Date().getMonth() + 1),
        year: String(new Date().getFullYear()),
      },
      deliveryDate: '',
      publishedDate: '',
    });
  };

  const handleDeleteComponent = (indexToDelete) => {
    remove(indexToDelete);
  };

  const handleFileChange = (event) => {
    const csv = event.target.files[0];

    if (csv.type !== 'text/csv') return;

    Papa.parse(csv, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const parsedCSV = results.data;
        const newGuidelines = [];

        parsedCSV.forEach((guideline) => {
          const deliveryType = guidelineDeliveryTypeOptions.find(
            (type) => type.value.toLowerCase() === guideline?.deliveryType.toLowerCase(),
          );
          const guidelineType = guidelineTypeOptions.find(
            (type) => type.value.toLowerCase() === guideline?.type.toLowerCase(),
          );
          const language = languageOptions.find(
            (lang) => lang.value.toLowerCase() === guideline?.language.toLowerCase(),
          );
          const totalSize = guideline?.totalSize ? +guideline?.totalSize : null;
          const searchVolume = guideline?.searchVolume ? +guideline?.searchVolume : null;

          const productionMonth = monthNumberLabel.find((month) => {
            const cleanMonth =
              guideline.productionDateMonth.substring(0, 1) === '0'
                ? guideline.productionDateMonth.substring(1)
                : guideline.productionDateMonth;

            return month.value === Number(cleanMonth);
          });

          const productionYear = {
            value: guideline?.productionDateYear,
            label: guideline?.productionDateYear,
          };

          const convertDateToTimestamp = (dateString) => {
            const [year, month, day] = dateString.split('-');
            const dateObject = new Date(year, month - 1, day);

            return dateObject.getTime();
          };

          const deliveryDate = guideline?.deliveryDate
            ? convertDateToTimestamp(guideline.deliveryDate)
            : null;

          const publishedDate = guideline?.publishedDate
            ? convertDateToTimestamp(guideline.publishedDate)
            : null;

          newGuidelines.push({
            deliveryType: deliveryType,
            reviewLink: guideline?.reviewLink,
            type: guidelineType,
            language,
            totalSize,
            primaryKeyword: guideline?.primaryKeyword,
            searchVolume,
            secondaryKeywords: guideline?.secondaryKeywords,
            productionDate: {
              month: productionMonth,
              year: productionYear,
            },
            deliveryDate: deliveryDate,
            publishedDate: publishedDate,
          });
        });

        replace(newGuidelines);
      },
    });
  };

  const getAccountTeam = () => {
    return new Promise(async (resolve, reject) => {
      const team = await firebaseHelper.getTeam(currentAccountId);
      if (team) {
        const select = Object.entries(team)
          .filter(([memberId]) => memberId !== 'initialTeamState')
          .map(([, memberInfo]) => ({
            value: memberInfo.id,
            label: memberInfo.name,
          }));
        resolve(select);
      }
    });
  };

  const getInternalTeam = () => {
    return new Promise(async (resolve, reject) => {
      const ectoTeam = await firebaseHelper.getTeam(process.env.REACT_APP_ECTO_ID);
      const select = Object.entries(ectoTeam).map(([accountId, memberInfo]) => ({
        value: memberInfo.id,
        label: memberInfo.name,
      }));
      resolve(select);
    });
  };

  const combinedTeamData = () => {
    return new Promise(async (resolve, reject) => {
      const internalTeam = await getInternalTeam();
      const accountTeam = await getAccountTeam();
      const combinedTeamData = [...internalTeam, ...accountTeam];

      const uniqueTeamData = [
        ...new Map(combinedTeamData.map((item) => [item.value, item])).values(),
      ];
      resolve(
        uniqueTeamData.map((item) => ({
          value: item.value,
          label: item.label,
        })),
      );
    });
  };

  useEffect(async () => {
    await combinedTeamData()
      .then((data) => {
        setTeamData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentAccountId]);

  useEffect(() => {
    if (!user) return;

    setValue('assignedTo', [{ value: user.id, label: user.name }]);
  }, [user]);

  useEffect(() => {
    if (!isPageLoading || !guidelineIds.length) return;

    const db = getDatabase();
    const guidelinesRef = ref(db, `guidelines/${currentAccountId}`);

    const handleValueChange = async (snapshot) => {
      const data = snapshot.val();
      if (!data) return;

      const guidelines = Object.entries(data).map(([guidelineId, guideline]) => ({
        id: guidelineId,
        ...guideline,
      }));

      if (guidelineIds.length === 1) {
        const guideline = guidelines.find((guideline) => guideline.id === guidelineIds[0]);
        if (guideline?.processing === false) navigate(`/guideline/${guidelineIds[0]}`);
      } else {
        const isProcessing = guidelines
          .filter((guideline) => guidelineIds.includes(guideline.id))
          .every((guideline) => !guideline.processing);

        if (!isProcessing) {
          setIsGuidelineCreated(true);
          setIsPageLoading(false);
          off(guidelinesRef);
        }
      }

      onValue(guidelinesRef, handleValueChange);

      return () => {
        off(guidelinesRef, 'value', handleValueChange);
      };
    };
  }, [isPageLoading]);

  return (
    <GuidelineLayout>
      <div className="page-content">
        <MetaTags>
          <title>{`${translate('Create Guideline')} | Ectools`}</title>
        </MetaTags>
        <Breadcrumbs
          title={translate('Guideline')}
          breadcrumbItem={translate('Create Guideline')}
        />
        {isGuidelineCreated && (
          <PageLoading
            title={translate('Guidelines created successfully!')}
            description={''}
            button={translate('See Guidelines')}
            buttonStyles={{ backgroundColor: '#556ee6' }}
            onClick={() => navigate('/guidelines')}
          >
            <div style={{ scale: '0.5' }}>
              <svg
                width="200"
                height="200"
                viewBox="0 0 72 72"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M36 4.5C18.6047 4.5 4.5 18.6047 4.5 36C4.5 53.3953 18.6047 67.5 36 67.5C53.3953 67.5 67.5 53.3953 67.5 36C67.5 18.6047 53.3953 4.5 36 4.5ZM49.6055 25.7133L34.7977 46.2445C34.5907 46.5334 34.3179 46.7688 34.0018 46.9312C33.6857 47.0935 33.3354 47.1782 32.9801 47.1782C32.6247 47.1782 32.2745 47.0935 31.9584 46.9312C31.6423 46.7688 31.3695 46.5334 31.1625 46.2445L22.3945 34.0945C22.1273 33.7219 22.3945 33.2016 22.8516 33.2016H26.1492C26.8664 33.2016 27.5484 33.5461 27.9703 34.1367L32.9766 41.0836L44.0297 25.7555C44.4516 25.1719 45.1266 24.8203 45.8508 24.8203H49.1484C49.6055 24.8203 49.8727 25.3406 49.6055 25.7133Z"
                  fill="#5B73E7"
                />
              </svg>
            </div>
          </PageLoading>
        )}
        {isPageLoading && !isGuidelineCreated && (
          <PageLoading
            title={translate('Creating guideline')}
            description={translate(
              'This may take a few minutes, you can also wait for this process in the background and return later.',
            )}
            buttonStyles={{ backgroundColor: '#556ee6' }}
            button={translate('Go back')}
            onClick={() => navigate('/guidelines')}
          />
        )}
        {!isPageLoading && !isGuidelineCreated && (
          <FormProvider {...form}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                const submit = handleSubmit(onSubmit);
                submit(e);
              }}
            >
              <div className="container-row">
                <div className="container-general-information">
                  <div className="header-general-information">
                    <div>
                      <button
                        aria-label="Back"
                        type="button"
                        id="back-button"
                        className="back-button"
                        onClick={() => window.history.back()}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M5.21875 7.33117H13.3334V8.6645H5.21875L8.79475 12.2405L7.85208 13.1832L2.66675 7.99783L7.85208 2.8125L8.79475 3.75517L5.21875 7.33117Z"
                            fill="#2D3748"
                          />
                        </svg>
                      </button>
                      <p>{translate('General Information')}</p>
                    </div>
                    <ButtonUploadCSV changeHandler={handleFileChange} />
                  </div>
                  <div className="container-cards">
                    {fields.map((field, index) => (
                      <ContentCard
                        key={field.id}
                        index={index}
                        canDelete={fields.length > 1}
                        onDelete={handleDeleteComponent}
                      />
                    ))}
                  </div>
                  <div className="button-add-new-text">
                    <button type="button" onClick={handleAddComponent}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                      >
                        <path
                          d="M7.83325 7.33594V3.33594H9.16659V7.33594H13.1666V8.66927H9.16659V12.6693H7.83325V8.66927H3.83325V7.33594H7.83325Z"
                          fill="#4A5568"
                        />
                      </svg>
                    </button>
                    <p>{translate('Add new text')}</p>
                  </div>
                </div>
                <div className="container-side-column">
                  <SendCard isLoading={isSending} />

                  <CardAssignedTo
                    assignedTo={watch('assignedTo')}
                    setAssignedTo={(value) => {
                      if (!value.length) return;
                      setValue('assignedTo', value);
                    }}
                    teamData={teamData}
                    title={translate('Assigned To')}
                  />
                </div>
              </div>
            </form>
          </FormProvider>
        )}
      </div>
    </GuidelineLayout>
  );
};

CreateGuideline.displayName = 'Create Guideline Page';

export default CreateGuideline;
