import Homepage from 'pages/homepage';
import KanbanBoard from '../pages/tasks/kanbanBoard';
import TasksList from '../pages/tasks/tasksList';
import TasksCreate from '../pages/tasks/createTask';
import EditTask from '../pages/tasks/editTask';
import EditProfile from '../pages/user/editProfile';
import Meetings from '../pages/meetings/listMeetings';
import Meeting from '../pages/meetings/meeting';
import createMeeting from '../pages/meetings/createMeeting';
import editMeeting from '../pages/meetings/editMeeting';
import Changelog from '../pages/support/changelog';
import CreateGuideline from 'pages/guideline/createGuideline';
import KanbanGuideline from 'pages/guideline/kanbanGuideline';
import ReviewGuideline from 'pages/guideline/reviewGuideline';
import GuidelineApproval from 'pages/guideline/guidelineApproval';
import manageGuideline from 'pages/guideline/manageGuideline';
import GuidelineMisc from 'pages/guideline/guidelineMisc';
import CreateContentStructure from 'pages/guideline/createContentStructure';
import PreReviewContent from 'pages/guideline/preReviewContent';
import ReviewContent from 'pages/guideline/reviewContent';
import InternalApproval from 'pages/guideline/internalApproval';
import PublishContent from 'pages/guideline/publishContent';
import ContentApproval from 'pages/guideline/contentApproval';
import ViewPublishedContent from 'pages/guideline/viewPublishedContent';

const authProtectedRoutes = [
  // Homepage
  { path: '/', component: Homepage },
  // Tasks
  { path: '/tasks', component: KanbanBoard },
  { path: '/tasks-list', component: TasksList },
  { path: '/tasks-create', component: TasksCreate },
  { path: '/edit-task', component: EditTask },
  { path: '/edit-profile', component: EditProfile },
  // Meetings
  { path: '/meetings', component: Meetings },
  { path: '/meeting', component: Meeting },
  // Meeting Management
  { path: '/create-meeting', component: createMeeting },
  { path: '/edit-meeting', component: editMeeting },
  // Changelog
  { path: '/changelog', component: Changelog },
  // Guideline
  { path: '/guidelines', component: KanbanGuideline },
  { path: '/guideline/:id', component: GuidelineMisc },
  { path: '/create-guideline', component: CreateGuideline },
  { path: '/guideline/manage', component: manageGuideline },
  { path: '/guideline/:id/review', component: ReviewGuideline },
  { path: '/guideline/:id/guideline-approval', component: GuidelineApproval },
  { path: '/content/:id/structure', component: CreateContentStructure },
  { path: '/content/:id/pre-review', component: PreReviewContent },
  { path: '/content/:id/review', component: ReviewContent },
  { path: '/content/:id/internal-approval', component: InternalApproval },
  { path: '/content/:id/content-approval', component: ContentApproval },
  { path: '/content/:id/publish', component: PublishContent },
  { path: '/content/:id/published-content', component: ViewPublishedContent },
];

export { authProtectedRoutes };
