import React, { useState } from 'react';
import MetaTags from 'react-meta-tags';
import {
  Container,
  Button,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  Alert,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import styled from 'styled-components';

import { getFirebaseBackend } from '../../helpers/firebaseHelper';
import LabelGroup from '../../components/Label-Group';
import TextInput from '../../components/Text-Input';
import SelectInput from '../../components/Select-Input';
import DateInput from '../../components/Date-Input';
import SpinnerInput from '../../components/Spinner-Input';
import Breadcrumbs from '../../components/Breadcrumb';
import { getFormValue, normalizeCode, copyToClipBoard } from '../../utils/';
import '../../assets/scss/custom/pages/_accounts.scss';
import { accountServices, accountModules } from '../../constants';

const CreateAccount = () => {
  // Form and States
  const formConfig = {
    defaultValues: {
      name: '',
      domain: '',
      status: [],
      plan: [],
      beginDate: '',
      contractTime: 12,
      onetime: false,
      services: [],
      modules: [],
      hours: 5,
      users: 5,
      gscLink: '',
      gaLink: '',
      internalTeam: [],
    },
    shouldFocusError: true,
  };
  const form = useForm(formConfig);
  const {
    handleSubmit,
    control,
    getValues,
    watch,
    reset,
    setError,
    formState: { errors },
  } = form;
  const { t: translate } = useTranslation();
  const firebaseHelper = getFirebaseBackend();
  const [file, setFile] = useState(null);
  const [modal, setModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorModal, setErrorModal] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const min = Math.ceil(10000);
  const max = Math.floor(99999);
  const [num1, setNum1] = useState(Math.floor(Math.random() * (max - min + 1)) + min);
  const [num2, setNum2] = useState(Math.floor(Math.random() * (max - min + 1)) + min);
  let code = `${normalizeCode(watch('name'))}-${num1}-${num2}`;

  const onSubmit = async (data) => {
    setIsSending(true);

    // Makes a copy of the beginDate object to calculate its ending
    const endDate = new Date(+data.beginDate);
    const account = {
      id: await firebaseHelper.newAccountKey(),
      code,
      name: data.name,
      domain: data.domain,
      status: data.status.value,
      plan: data.plan.value,
      hasLogo: file !== null,
      file: file !== null ? file : null,
      beginDate: data.beginDate ? Date.parse(data.beginDate) : null,
      endDate:
        data.contractTime !== 0 ? endDate.setMonth(endDate.getMonth() + data.contractTime) : null,
      contractTime: data.contractTime,
      onetime: data.onetime,
      services: data.services.map(getFormValue),
      modules: data.modules.reduce((acc, cur) => ({ ...acc, [cur.value]: true }), {}),
      limits: {
        hours: data.hours,
        users: data.users,
      },
      gscLink: data.gscLink || null,
      gaLink: data.gaLink || null,
      internalTeam: data.internalTeam.map(getFormValue),
    };

    try {
      const accountId = account.id;

      if (data.modules.some((module) => module.value === 'content')) {
        await handleCreateNewFolder(accountId, account.name);
      }

      if (account.hasLogo) {
        await firebaseHelper.updateAccountLogo(file, account.id);
      }

      await firebaseHelper.registerAccount(account);

      setModal(true);
      setIsSending(false);
    } catch (error) {
      setIsSending(false);
      console.error(error);
      setErrorMessage('An error occurred when trying to register the account.');
      setErrorModal(true);
    }
  };

  const onInvalid = (errors) => {
    if (getValues('keywordReport') === 0) {
      setError('keywordReport', { type: 'manual', message: 'The value cannot be 0.' });
    }
    if (Object.values(errors).length) {
      console.error('Error saving changes');
      window.scrollTo({ top: 0, behavior: 'smooth' });
      return;
    }
  };

  const handleCreateNewFolder = async (accountId, accountName) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_GDOCS_WEBHOOK_CREATE_FOLDER,
        {
          accountName,
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_GDOCS_FLOW_KEY}`,
          },
        },
      );

      if (response.data) {
        await firebaseHelper.saveGDriveFolderLink(accountId, response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const resetForm = () => {
    reset();
    setFile(null);
    document.getElementById('file-input').value = null;
    randomizeCode();
  };

  const closeModalHandle = () => {
    resetForm();
    setModal(false);
  };

  const handleFileSubmit = (event) => {
    if (!event?.target?.files[0]) {
      return;
    }
    if (
      !(
        event?.target?.files[0].type === 'image/png' ||
        event?.target?.files[0].type === 'image/jpg' ||
        event?.target?.files[0].type === 'image/jpeg'
      )
    ) {
      alert(
        translate(
          'The profile photo file extesion needs to be one of the following: png, jpg, jpeg.',
        ),
      );
    } else {
      setFile(event?.target?.files[0]);
    }
  };

  const randomizeCode = () => {
    let newNum1 = Math.floor(Math.random() * (max - min + 1)) + min;
    let newNum2 = Math.floor(Math.random() * (max - min + 1)) + min;
    while (newNum1 === num1) {
      newNum1 = Math.floor(Math.random() * (max - min + 1)) + min;
    }
    while (newNum2 === num2) {
      newNum2 = Math.floor(Math.random() * (max - min + 1)) + min;
    }
    setNum1(newNum1);
    setNum2(newNum2);
  };

  const getInternalTeam = () => {
    return new Promise(async (resolve, reject) => {
      const ectoTeam = await firebaseHelper.getTeam(process.env.REACT_APP_ECTO_ID);
      const select = [];
      Object.entries(ectoTeam).forEach(([accountId, memberInfo], index) => {
        select[index] = { value: memberInfo.id, label: memberInfo.name };
      });
      resolve(select);
    });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{translate('Create Account')} | Ectools</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs title={translate('Accounts')} breadcrumbItem={translate('Create')} />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Modal
                    isOpen={modal}
                    role="dialog"
                    autoFocus={true}
                    centered={true}
                    className="exampleModal"
                    tabIndex="-1"
                  >
                    <ModalHeader>
                      <div className="modal--header">
                        <i className="bx bx-check-double modal--icon" />
                        <span className="modal--title">
                          {translate('Account created Successfully!')}
                        </span>
                      </div>
                    </ModalHeader>
                    <ModalBody>
                      <p>
                        {translate(
                          'The account was successfully created. You can see the existing ones or create more.',
                        )}
                      </p>
                    </ModalBody>
                    <ModalFooter>
                      <Link to="/list-accounts">
                        <button className="modal--button-two">{translate('See Accounts')}</button>
                      </Link>
                      <button className="modal--button-one" onClick={closeModalHandle}>
                        {translate('Create Account')}
                      </button>
                    </ModalFooter>
                  </Modal>
                  <Modal
                    isOpen={errorModal}
                    role="dialog"
                    autoFocus={true}
                    centered={true}
                    className="exampleModal"
                    tabIndex="-1"
                  >
                    <ModalHeader>
                      <div className="modal--header">
                        <i className="bx bx-error-circle modal--icon" style={{ color: 'red' }} />
                        <span className="modal--title">
                          {translate('An Unexpected Error Occured!')}
                        </span>
                      </div>
                    </ModalHeader>
                    <ModalBody>
                      <p>{translate(errorMessage)}</p>
                    </ModalBody>
                    <ModalFooter>
                      <Link to="/list-accounts">
                        <button className="modal--button-two">{translate('Return')}</button>
                      </Link>
                    </ModalFooter>
                  </Modal>

                  <CardTitle className="mb-2">{translate('Add New Account')}</CardTitle>

                  <FormProvider {...form}>
                    <form onSubmit={handleSubmit(onSubmit, onInvalid)}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div style={{ width: '49%' }}>
                          <LabelGroup htmlFor="name" label="Account's Name" mb={1} column required>
                            <TextInput
                              controlName="name"
                              placeholder="Name"
                              validation={{
                                pattern: {
                                  value: /[A-Za-z_]/,
                                  message: 'Name must contain at least a letter!',
                                },
                              }}
                              noEllipsis
                              required
                            />
                            {errors.name && (
                              <span style={{ color: 'red' }}>{translate(errors.name.message)}</span>
                            )}
                          </LabelGroup>
                          <LabelGroup htmlFor="domain" label="Domain" mb={1} column required>
                            <TextInput
                              controlName="domain"
                              placeholder=""
                              validation={{
                                pattern: {
                                  value: /^(?!:\/\/)([a-zA-Z0-9-]{1,63}\.)+[a-zA-Z]{2,63}$/,
                                  message: 'Enter a valid domain!',
                                },
                              }}
                              noEllipsis
                              required
                            />
                            {errors.domain && (
                              <span style={{ color: 'red' }}>
                                {translate(errors.domain.message)}
                              </span>
                            )}
                          </LabelGroup>

                          <LabelGroup htmlFor="status" label="Status" mb={1} column required>
                            <SelectInput
                              controlName="status"
                              options={[
                                { value: true, label: translate('Activated') },
                                {
                                  value: false,
                                  label: translate('Deactivated'),
                                },
                              ]}
                              required
                            />
                            {errors.status && (
                              <span style={{ color: 'red' }}>
                                {translate(errors.status.message)}
                              </span>
                            )}
                          </LabelGroup>

                          <LabelGroup htmlFor="plan" label="Plan" mb={1} column required>
                            <SelectInput
                              controlName="plan"
                              options={[
                                {
                                  value: 'Start',
                                  label: translate('Start Plan'),
                                },
                                { value: 'Pro', label: translate('Pro Plan') },
                                {
                                  value: 'Enterprise',
                                  label: translate('Enterprise Plan'),
                                },
                                {
                                  value: 'Personalized',
                                  label: translate('Personalized Plan'),
                                },
                              ]}
                              required
                            />
                            {errors.plan && (
                              <span style={{ color: 'red' }}>{translate(errors.plan.message)}</span>
                            )}
                          </LabelGroup>

                          <LabelGroup htmlFor="file-input" label="Logo" mb={1} column>
                            <Input
                              id="file-input"
                              className="form-control"
                              type="file"
                              onChange={handleFileSubmit}
                              accept="image/png, image/jpg, image/jpeg"
                            />
                          </LabelGroup>

                          <LabelGroup htmlFor="beginDate" label="Begin Date" mb={1} column required>
                            <DateInput
                              controlName="beginDate"
                              placeholder="Account's Beginning Date"
                              required
                            />
                            {errors.beginDate && (
                              <span style={{ color: 'red' }}>
                                {translate(errors.beginDate.message)}
                              </span>
                            )}
                          </LabelGroup>

                          <LabelGroup
                            htmlFor="contractTime"
                            label="Contract Time (months)"
                            mb={1}
                            column
                            required
                          >
                            <div className="d-flex flex-row">
                              <SpinnerInput controlName="contractTime" size={4} min={1} required />
                              <Controller
                                control={control}
                                name="onetime"
                                render={({ field }) => (
                                  <input
                                    {...field}
                                    style={{
                                      alignSelf: 'center',
                                      marginLeft: '1em',
                                    }}
                                    name="onetime"
                                    type="checkbox"
                                  />
                                )}
                              />
                              <label
                                style={{
                                  margin: '0 0 0 1em',
                                  alignSelf: 'center',
                                }}
                                htmlFor="onetime"
                              >
                                {translate('This is a one-time contract or without renovation')}
                              </label>
                            </div>
                            {errors.contractTime && (
                              <span style={{ color: 'red' }}>
                                {translate(errors.contractTime.message)}
                              </span>
                            )}
                          </LabelGroup>

                          <LabelGroup
                            htmlFor="services"
                            label="Contracted Services"
                            mb={1}
                            column
                            required
                          >
                            <SelectInput
                              controlName="services"
                              isMulti
                              options={accountServices}
                              required
                            />
                            {errors.services && (
                              <span style={{ color: 'red' }}>
                                {translate(errors.services.message)}
                              </span>
                            )}
                          </LabelGroup>

                          <LabelGroup
                            htmlFor="modules"
                            label="Contracted Modules"
                            mb={1}
                            column
                            required
                          >
                            <SelectInput
                              controlName="modules"
                              isMulti
                              options={accountModules}
                              required
                            />
                            {errors.modules && (
                              <span style={{ color: 'red' }}>
                                {translate(errors.modules.message)}
                              </span>
                            )}
                          </LabelGroup>

                          <LabelGroup htmlFor="limits" label="Limits" mb={1} column>
                            <SpinnerInput
                              controlName="hours"
                              label="Allocated Hours"
                              size={12}
                              min={1}
                              mb={2}
                            />
                            <SpinnerInput
                              controlName="users"
                              label="Users"
                              size={12}
                              min={1}
                              mb={2}
                            />
                          </LabelGroup>
                        </div>

                        <div
                          style={{
                            height: 'auto',
                            width: '1px',
                            backgroundColor: '#E2E2E2',
                          }}
                        />

                        <div style={{ width: '49%' }}>
                          <FormGroup className="mb-1" row>
                            <Col lg="11">
                              <Label className="col-form-label col-lg-12">
                                {translate('Client Code')}
                              </Label>
                              <div style={{ display: 'flex' }}>
                                <Col lg="9">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={getValues('name') === '' ? '' : code}
                                    readOnly
                                  />
                                </Col>
                                <Col
                                  lg="3"
                                  style={{
                                    display: 'flex',
                                    boxSizing: 'border-box',
                                  }}
                                >
                                  <Button
                                    style={{
                                      width: '4em',
                                      marginLeft: '1.5em',
                                      padding: '0 0',
                                      backgroundColor: '#F1B44C',
                                      border: 'none',
                                    }}
                                    onClick={randomizeCode}
                                  >
                                    <i className="bx bx-repost" style={{ fontSize: '1.5rem' }} />
                                  </Button>
                                  <Button
                                    style={{
                                      width: '4em',
                                      marginLeft: '1.5em',
                                      padding: '0 0',
                                      backgroundColor: '#596FDE',
                                      border: 'none',
                                    }}
                                    onClick={(e) => copyToClipBoard(e, code)}
                                  >
                                    <i className="bx bx-copy" style={{ fontSize: '1.5rem' }} />
                                  </Button>
                                </Col>
                              </div>
                            </Col>
                          </FormGroup>

                          <LabelGroup
                            htmlFor="internalTeam"
                            label="Ecto's Internal Team"
                            mb={1}
                            column
                            required
                          >
                            <SelectInput
                              asyncronous
                              isMulti
                              controlName="internalTeam"
                              cacheOptions
                              defaultOptions
                              loadOptions={getInternalTeam}
                              required
                            />
                            {errors.internalTeam && (
                              <span style={{ color: 'red' }}>
                                {translate(errors.internalTeam.message)}
                              </span>
                            )}
                          </LabelGroup>

                          <LabelGroup
                            htmlFor="gscLink"
                            label="Google Search Console Link"
                            mb={1}
                            column
                          >
                            <TextInput controlName="gscLink" placeholder="" noEllipsis />
                          </LabelGroup>

                          <LabelGroup htmlFor="gaLink" label="Google Analytics Link" mb={1} column>
                            <TextInput controlName="gaLink" placeholder="" noEllipsis />
                          </LabelGroup>
                        </div>
                      </div>

                      <ButtonSubmitStyles
                        type="submit"
                        className="mt-3"
                        style={{
                          border: 'none',
                          minWidth: '10rem',
                          minHeight: '2.5rem',
                          maxHeight: '2.5rem',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          padding: 0,
                          position: 'relative',
                        }}
                      >
                        {isSending ? (
                          <div
                            className="spinner-border text-light"
                            role="status"
                            style={{
                              width: '1.5rem',
                              height: '1.5rem',
                              position: 'absolute',
                            }}
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          translate('Register Account')
                        )}
                      </ButtonSubmitStyles>
                    </form>
                  </FormProvider>
                  <div
                    style={{
                      display: Object.values(errors).length ? 'flex' : 'none',
                      flexDirection: 'column',
                      alignItems: 'center',
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      border: '1px solid #F46A6A',
                      borderRadius: '4px',
                      overflow: 'hidden',
                      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                    }}
                  >
                    <Alert
                      color="light"
                      style={{
                        border: 'none',
                        borderRadius: 0,
                        margin: 0,
                        width: '100%',
                        borderBottom: '1px solid #EAD2D6',
                        padding: '12px',
                      }}
                    >
                      {translate('Unable to save changes')}
                    </Alert>
                    <Alert
                      color="danger"
                      style={{
                        border: 'none',
                        borderRadius: 0,
                        margin: 0,
                        padding: '12px',
                        color: '#000',
                        fontWeight: 500,
                        paddingRight: '48px',
                      }}
                    >
                      {translate('One or more required fields are empty')}
                    </Alert>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const ButtonSubmitStyles = styled(Button)`
  background-color: #34c38f;
  border: none;
  &:hover {
    background-color: #25855a;
  }
`;

export default CreateAccount;
