import React, { useState, useEffect, useRef } from 'react';
import SimpleBar from 'simplebar-react';
import { Link, useHref } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getFirebaseBackend } from 'helpers/firebaseHelper';
import { useAuth } from 'hooks/useAuth';
import { QuickMenuLink, SidebarContentStyles } from './styles';

const SidebarContent = () => {
  const firebaseHelper = getFirebaseBackend();
  const { user } = useAuth();
  const { t: translate } = useTranslation();
  const [accountModules, setAccountModules] = useState({});
  const barRef = useRef();
  const currentRoute = useHref();

  async function fetchAccountModules() {
    const accountObj = await firebaseHelper.getAccount(user?.account);
    setAccountModules(accountObj.modules);
  }

  const verifyRoute = (route) => {
    if (currentRoute.startsWith(route) && route !== '/') {
      return true;
    }
    if (currentRoute === route) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (!user?.account) return;
    fetchAccountModules();
  }, [user]);

  useEffect(() => {
    barRef.current.recalculate();
  });

  return (
    <SidebarContentStyles>
      <SimpleBar className="h-100" ref={barRef}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to={user.adminStatus === 'Reviewer' ? '/guidelines' : '/#'} className="">
                <i className={'bx bx-home-circle' + (verifyRoute('/') ? ' link-bold-white' : '')} />
                <span className={verifyRoute('/') ? ' link-bold-white' : ''}>
                  {translate('Home')}
                </span>
              </Link>
            </li>

            {((user?.modules?.content && accountModules?.content) ||
              user.adminStatus === 'Super Admin') && (
              <li>
                <QuickMenuLink to="/guideline/manage">
                  <i
                    className={
                      'bx bx-bell m-0' +
                      (verifyRoute('/guideline/manage') ? ' link-bold-white' : '')
                    }
                  />

                  {/* didn't work with 
                    className={
                          verifyRoute('/guideline/manage')
                            ? 'link-bold-white'
                            : ''
                    } 
                  */}
                  <span
                    className={'' + (verifyRoute('/guideline/manage') ? ' link-bold-white' : '')}
                  >
                    {translate('My Activity')}
                  </span>
                </QuickMenuLink>
              </li>
            )}

            <li className="menu-title mt-3">{translate('Apps')}</li>

            {user?.modules?.tasks && accountModules?.tasks && (
              <li>
                <Link to="/tasks">
                  <i className="bx bx-calendar-check"></i>
                  <span>{translate('Tasks')}</span>
                </Link>
                <ul>
                  <li>
                    <Link
                      to="/tasks"
                      className={
                        verifyRoute('/tasks') && !verifyRoute('/tasks-list')
                          ? 'link-bold-white'
                          : ''
                      }
                    >
                      {translate('Kanban')}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/tasks-list"
                      className={verifyRoute('/tasks-list') ? 'link-bold-white' : ''}
                    >
                      {translate('List')}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/meetings"
                      className={verifyRoute('/meetings') ? 'link-bold-white' : ''}
                    >
                      {translate('Meetings')}
                    </Link>
                  </li>
                </ul>
              </li>
            )}

            {user?.modules?.courses && accountModules?.courses && (
              <li>
                <Link to="/#">
                  <i className="bx bx-book"></i>
                  <span>{translate('Education')}</span>
                </Link>
                <ul>
                  <li>
                    <Link
                      to="/lessons"
                      className={verifyRoute('/lessons') ? 'link-bold-white' : ''}
                    >
                      {translate('Lessons')}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/courses"
                      className={verifyRoute('/courses') ? 'link-bold-white' : ''}
                    >
                      {translate('Courses')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/tests" className={verifyRoute('/tests') ? 'link-bold-white' : ''}>
                      {translate('Tests')}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/library"
                      className={verifyRoute('/library') ? 'link-bold-white' : ''}
                    >
                      {translate('My Library')}
                    </Link>
                  </li>
                </ul>
              </li>
            )}

            {(user?.modules?.content && accountModules?.content) ||
            user.adminStatus === 'Reviewer' ||
            user.adminStatus === 'Super Admin' ||
            user.adminStatus === 'Admin' ? (
              <li>
                <Link to="/guidelines">
                  <i className="bx bx-file"></i>
                  <span>{translate('Content')}</span>
                </Link>
                <ul>
                  <li>
                    <Link
                      to="/guidelines"
                      className={
                        verifyRoute('/content') || verifyRoute('/guidelines')
                          ? 'link-bold-white'
                          : ''
                      }
                    >
                      {translate('Guidelines')}
                    </Link>
                  </li>
                </ul>
              </li>
            ) : (
              <></>
            )}

            {user?.modules?.keywords && accountModules?.keywords && (
              <li>
                <Link to="/keywords" className={verifyRoute('/keywords') ? 'link-bold-white' : ''}>
                  <i className="bx bx-pulse"></i>
                  <span>{translate('Keywords')}</span>
                </Link>
              </li>
            )}

            {user?.modules?.perfomance && accountModules?.perfomance && (
              <li>
                <Link
                  to="/pagespeed"
                  className={verifyRoute('/pagespeed') ? 'link-bold-white' : ''}
                >
                  <i className="bx bx-tachometer"></i>
                  <span>{translate('PageSpeed')}</span>
                </Link>
              </li>
            )}

            {user?.modules?.report && accountModules?.report && (
              <li>
                <Link to="/report" className={verifyRoute('/report') ? 'link-bold-white' : ''}>
                  <i className="bx bx-bar-chart"></i>
                  <span>{translate('Report')}</span>
                </Link>
              </li>
            )}

            {user?.modules?.tools && accountModules?.tools && (
              <li>
                <Link to="/tools" className={verifyRoute('/tools') ? 'link-bold-white' : ''}>
                  <i className="bx bx-wrench"></i>
                  <span>{translate('Tools')}</span>
                </Link>
              </li>
            )}

            <li>
              <Link to="/changelog">
                <i className="bx bx-wrench"></i>
                <span>{translate('Support')}</span>
              </Link>
              <ul>
                <li>
                  <Link
                    to="/changelog"
                    className={verifyRoute('/changelog') ? 'link-bold-white' : ''}
                  >
                    {translate("What's New")}
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </SidebarContentStyles>
  );
};

export default SidebarContent;
