import {
  getDatabase,
  set,
  ref,
  get,
  update,
  remove,
  push,
  serverTimestamp,
} from 'firebase/database';
import { initializeApp } from 'firebase/app';
import {
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import {
  getStorage,
  listAll,
  getMetadata,
  ref as refStorage,
  uploadBytes,
  getDownloadURL,
  uploadBytesResumable,
  deleteObject,
} from 'firebase/storage';
import { isEmpty, cloneDeep } from 'lodash';
import axios from 'axios';

class FirebaseAuthBackend {
  constructor(firebaseConfig) {
    if (firebaseConfig) {
      // Initialize Firebase
      initializeApp(firebaseConfig);

      const auth = getAuth();
      onAuthStateChanged(auth, (user) => {
        if (user) {
          localStorage.setItem('authUser', JSON.stringify(user));
        } else {
          localStorage.removeItem('authUser');
        }
      });
    }
  }

  // TASKS METHODS

  /**
   * Registers the task info on the firebase realtime database
   * @param {object} task Object with all task's information, including user, project and task Id
   * @returns {Promise<Boolean|String>} Promise that resolves true when the data is stored successfully or rejects the error message
   */
  registerTask = (task) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const tasksRef = ref(db, `tasks/${task.clientId}/${task.taskId}`);

      set(tasksRef, {
        id: task.taskId,
        name: task.name,
        description: task.description,
        dateBegin: task.dateBegin,
        dateDelivery: task.dateDelivery,
        dateImplementation: task.dateImplementation,
        assignedTo: task.assignedTo,
        foundations: task.foundations,
        status: task.status,
        impact: task.impact,
        complexity: task.complexity,
        hasFiles: task.hasFiles,
        column: task.column,
        position: task.position,
        createdAt: serverTimestamp(),
      })
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(new Error(`Failed to register task: ${error.message}`));
        });
    });
  };

  /**
   * Returns the value from the firebase realtime database from the specified task object with the necessary Ids given
   * @param {object} task Object with all task's information, including user, project and task Id
   * @returns {Promise<object|String>} Promise that resolves the value of the specified task or rejects the error message
   */
  getTask = (task) => {
    return new Promise(async (resolve, reject) => {
      try {
        const db = getDatabase();
        const taskRef = ref(db, `tasks/${task.clientId}/${task.taskId}`);
        const snapshot = await get(taskRef);
        const databaseValue = snapshot.val();

        if (databaseValue) {
          resolve(databaseValue);
        } else {
          reject("This task doesn't exist");
        }
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   * Returns an array from the firebase realtime database with all the tasks of a specified account and project
   * @param {String} accountId String with the user's Firebase Auth Unique Id
   * @returns {Promise<object|String>} Promise that resolves an array of all the project's tasks or rejects the error message
   */
  getAllTasks = (accountId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const db = getDatabase();
        const tasksRef = ref(db, `tasks/${accountId}`);
        const snapshot = await get(tasksRef);
        const databaseValue = snapshot.val();

        if (databaseValue) {
          resolve(databaseValue);
        } else {
          reject('No tasks found');
        }
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   * Updates the task data on the firebase realtime database removing the unnecessary keys. It needs to include every key value!
   * @param {object} task Object with all task's information, including user, project and task Id
   * @returns {Promise<Boolean|String>} Promise that resolves true when the task is updated successfully or rejects the error message
   */
  updateTask = (task) => {
    return new Promise(async (resolve, reject) => {
      try {
        const db = getDatabase();
        const updates = {};
        const pureTask = JSON.parse(JSON.stringify(task));
        // Remove unnecessary fields to lighten the tasks on firebase
        delete pureTask.clientId;
        delete pureTask.accountId;
        delete pureTask.projectId;
        delete pureTask.taskId;
        delete pureTask.assignedToNames;
        delete pureTask.checkStats;

        updates[`/tasks/${task.clientId}/${task.id}`] = pureTask;

        await update(ref(db), updates);
        resolve(true);
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   * Removes the task data from the firebase realtime database
   * @param {String} accountId Account's Unique Id
   * @param {String} taskId Task's Unique Id
   * @returns {Promise<Boolean|String>} Promise that resolves true when the data is removed successfully or rejects the error message
   */
  removeTask = (accountId, taskId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const db = getDatabase();
        const taskRef = ref(db, `tasks/${accountId}/${taskId}`);

        await remove(taskRef);
        resolve(true);
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   * Duplicates the task data on the firebase realtime database
   * @param {String} accountId Account's Unique Id
   * @param {String} taskId Task's Unique Id
   * @param {String} userId User's Unique Id
   * @returns {Promise<Boolean|String>} Promise that resolves true when the data is duplicated successfully or rejects the error message
   * */
  duplicateTask = async (accountId, taskId, userId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const db = getDatabase();
        const taskRef = ref(db, `tasks/${accountId}/${taskId}`);
        const snapshot = await get(taskRef);
        const task = snapshot.val();

        if (!task) {
          reject('Task not found');
          return;
        }

        const newTaskId = this.newTaskKey(accountId);
        const duplicateTask = {
          ...task,
          description: task?.description || null,
          dateBegin: task?.dateBegin || null,
          dateDelivery: task?.dateDelivery || null,
          dateImplementation: task?.dateImplementation || null,
          impact: task?.impact || null,
          complexity: task?.complexity || null,
          name: `${task.name} (Cópia)`,
          hasFiles: false,
          id: newTaskId,
          taskId: newTaskId,
          clientId: accountId,
          assignedTo: [userId],
        };

        await this.registerTask(duplicateTask);

        if (task.hasChecklist) {
          await this.registerChecklist({ clientId: accountId, taskId: newTaskId, accountId });
          const checklist = await this.getChecklist({ accountId, taskId });

          for (const check of Object.values(checklist)) {
            await this.registerCheck({
              ...check,
              accountId,
              taskId: newTaskId,
              clientId: accountId,
            });
          }
        }

        resolve(true);
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   * Returns a newly pushed key from the tasks path
   * @param {String} accountId Account's Unique Id
   * @returns {String} String with a non used Unique Key on Firebase's Database
   */
  newTaskKey = (accountId) => {
    const db = getDatabase();
    const tasksRef = ref(db, `tasks/${accountId}`);
    const newTaskRef = push(tasksRef);
    return newTaskRef.key;
  };

  /**
   * Returns the realtime database name of the user from the id given
   * @param {String} taskId String with the unique id of an user
   * @returns {Promise<object|String>} Promise that resolves the user's name or rejects the error message
   */
  taskName = async (accountId, taskId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const db = getDatabase();
        const taskRef = ref(db, `tasks/${accountId}/${taskId}`);
        const snapshot = await get(taskRef);
        const databaseValue = snapshot.val();

        if (databaseValue) {
          resolve(databaseValue.name);
        } else {
          reject(`This task doesn't exist: tasks/${accountId}/${taskId}`);
        }
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   * Updates the task's dateDelivery on the firebase realtime database
   * @param {String} accountId Account's Unique Id
   * @param {String} taskId Task's Unique Id
   * @param {String} dateDelivery Task's new dateDelivery
   * @returns {Promise<Boolean|String>} Promise that resolves true when the data is updated successfully or rejects the error message
   * */
  updateTaskDateDelivery = (accountId, taskId, dateDelivery) => {
    return new Promise(async (resolve, reject) => {
      try {
        const db = getDatabase();
        const taskDateDeliveryRef = ref(db, `tasks/${accountId}/${taskId}/dateDelivery`);
        await set(taskDateDeliveryRef, dateDelivery);
        resolve(true);
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   * Updates the task's dateImplementation on the firebase realtime database
   * @param {String} accountId Account's Unique Id
   * @param {String} taskId Task's Unique Id
   * @param {String} dateImplementation Task's new dateImplementation
   * @returns {Promise<Boolean|String>} Promise that resolves true when the data is updated successfully or rejects the error message
   * */
  updateTaskDateImplementation = (accountId, taskId, dateImplementation) => {
    return new Promise(async (resolve, reject) => {
      try {
        const db = getDatabase();
        const taskDateImplementationRef = ref(
          db,
          `tasks/${accountId}/${taskId}/dateImplementation`,
        );
        await set(taskDateImplementationRef, dateImplementation);
        resolve(true);
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   * Updates the task's dateBegin on the firebase realtime database
   * @param {String} accountId Account's Unique Id
   * @param {String} taskId Task's Unique Id
   * @param {String} dateBegin Task's new dateBegin
   * @returns {Promise<Boolean|String>} Promise that resolves true when the data is updated successfully or rejects the error message
   * */
  updateTaskDateBegin = (accountId, taskId, dateBegin) => {
    return new Promise(async (resolve, reject) => {
      try {
        const db = getDatabase();
        const taskDateBeginRef = ref(db, `tasks/${accountId}/${taskId}/dateBegin`);
        await set(taskDateBeginRef, dateBegin);
        resolve(true);
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   * Returns the last position of the specified column in the project's kanban board
   * @param {String} accountId Account's Unique Id
   * @param {String} column Kanban's Column Name where the last position will be checked
   * @returns {Promise<Number|String>} Promise that resolves the last position of the colum or rejects the error message
   */
  getLastPosition = (accountId, column) => {
    return new Promise(async (resolve, reject) => {
      try {
        const db = getDatabase();
        const tasksRef = ref(db, `tasks/${accountId}`);
        const snapshot = await get(tasksRef);
        const databaseValue = snapshot.val();

        if (databaseValue) {
          let higher = -1;
          Object.entries(databaseValue).forEach(([_, task]) => {
            if (task.column === column && task.position > higher) {
              higher = task.position;
            }
          });
          resolve(++higher);
        } else {
          resolve(0);
        }
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   * Updates the task's timeSpent on the firebase realtime database
   * @param {String} accountId Account's Unique Id
   * @param {String} taskId Task's Unique Id
   * @param {Number} timeSpent Task's new timeSpent
   * @returns {Promise<Boolean|String>} Promise that resolves true when the data is updated successfully or rejects the error message
   * */
  setTaskTimeSpent = (accountId, taskId, timeSpent) => {
    return new Promise((resolve, reject) => {
      try {
        const db = getDatabase();
        const timeSpentRef = ref(db, `tasks/${accountId}/${taskId}/timeSpent`);
        set(timeSpentRef, timeSpent)
          .then(() => resolve(true))
          .catch((error) => reject(this._handleError(error)));
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  // USER METHODS

  /**
   * Registers the user when done by the register page with an account code
   * @param {String} email String with the user's email
   * @param {String} password String with the user's password
   * @param {String} username String with the user's name
   * @param {String} code String with the account code given by the user
   * @returns {Promise<String>} Promise that resolves true when the data is stored successfully or rejects the error message
   */
  registerUser = (email, password, username, code) => {
    return new Promise(async (resolve, reject) => {
      try {
        const auth = getAuth();
        const { user } = await createUserWithEmailAndPassword(auth, email, password);
        await sendEmailVerification(user);

        const uid = user.uid;
        await this.logout();

        try {
          const accountId = await this.codeAccount(code);
          await this.addUserToDataBase(email, username, uid, accountId);
          resolve(user);
        } catch (error) {
          reject(this._handleError(error));
        }
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   * Registers the user when done by a super admin with additional info and without account code
   * @param {object} user Object with all user's information collected on user creation page
   * @returns {Promise<String>} Promise that resolves true when the data is stored successfully or rejects the error message
   */
  registerUserComplete = (user) => {
    return new Promise(async (resolve, reject) => {
      try {
        const auth = getAuth();
        const { user: firebaseUser } = await createUserWithEmailAndPassword(
          auth,
          user.email,
          user.password,
        );
        const uid = firebaseUser.uid;

        await this.addFullUserToDB(user, uid);

        if (user.hasPhoto) {
          await this.storeUserPhoto(user.file, user.id);
        }

        await sendEmailVerification(firebaseUser);
        resolve(firebaseUser);
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   * Adds an user's ectools info on the firebase realtime database, used by the registerUser method
   * @param {String} userEmail String with the user's email
   * @param {String} userName String with the user's Name
   * @param {String} userUid String with the user's Firebase Auth unique id
   * @param {String} accountUid String with the user's Account unique id
   * @returns {Promise<Boolean|String>} Promise that resolves true when the data is stored successfully or rejects the error message
   */
  addUserToDataBase = (userEmail, userName, userUid, accountUid) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      set(ref(db, `users/${userUid}`), {
        id: userUid,
        account: accountUid,
        status: 'Pending',
        email: userEmail,
        modules: { tasks: true },
        name: userName,
        adminStatus: 'Client',
        hasPhoto: false,
        createdAt: serverTimestamp(),
      }).then(
        () => resolve(true),
        (error) => reject(this._handleError(error)),
      );
    });
  };

  /**
   * Adds an user's ectools info on the firebase realtime database, used by the registerUserComplete method
   * @param {object} user Object with all user's information collected on user creation page
   * @param {String} userUid String with the user's Firebase Auth unique id
   * @returns {Promise<object|String>} Promise that resolves true when the data is stored successfully or rejects the error message
   */
  addFullUserToDB = (user, userUid) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      set(ref(db, `users/${userUid}`), {
        id: userUid,
        account: user.account,
        status: 'Pending',
        email: user.email,
        name: user.name,
        adminStatus: 'Client',
        hasPhoto: user.hasPhoto,
        modules: user.modules,
        occupation: user.occupation,
        birthDate: user.birthDate,
        phones: user.phones,
        zipCode: user.zipCode,
        addressLine1: user.addressLine1,
        addressLine2: user.addressLine2,
        state: user.state,
        city: user.city,
        emailNotification: user.emailNotification,
        createdAt: serverTimestamp(),
      }).then(
        () => resolve(true),
        (error) => reject(this._handleError(error)),
      );
    });
  };

  /**
   * Returns an array with all the users registered on the firebase realtime database
   * @returns {Promise<object|String>} Promise that resolves the array with all the users or rejects the error message
   */
  getAllUsers = () => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      get(ref(db, 'users/'))
        .then((snapshot) => {
          if (snapshot.exists()) {
            resolve(snapshot.val());
          } else {
            resolve(null);
          }
        })
        .catch((error) => reject(this._handleError(error)));
    });
  };

  /**
   * Returns the user database value for the unique id given
   * @param {String} userId String of the unique id of a firebase authenticated user
   * @returns {Promise<object|String>} Promise that resolves the user data or rejects the error message
   */
  getUser = (userId) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      get(ref(db, `users/${userId}`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            resolve(snapshot.val());
          } else {
            reject("This user doesn't exist");
          }
        })
        .catch((error) => reject(this._handleError(error)));
    });
  };

  /**
   * Returns only the user name database value for the unique id given
   * @param {String} userId String of the unique id of a firebase authenticated user
   * @returns {Promise<object|String>} Promise that resolves the user data or rejects the error message
   */
  getUserName = (userId) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      get(ref(db, `users/${userId}/name`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            resolve(snapshot.val());
          } else {
            reject("This user doesn't exist");
          }
        })
        .catch((error) => reject(this._handleError(error)));
    });
  };

  /**
   * Updates the user data on the firebase realtime database. It needs to include every key value!
   * @param {object} user Object with all the necessary user data including its id
   * @returns {Promise<Boolean|String>} Promise that resolves true when the data is updated successfully or rejects the error message
   */
  updateUser = (user) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const updates = {};
      updates[`users/${user.id}`] = user;

      update(ref(db), updates)
        .then(() => resolve(true))
        .catch((error) => reject(this._handleError(error)));
    });
  };

  /**
   * Change a firebase authenticated user's password
   * @param {String} newPassword
   * @returns {Promise<Boolean|String>} Promise that resolves true when the password is changed successfully or rejects the error message
   */
  updateUserPassword = (newPassword) => {
    return new Promise((resolve, reject) => {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        updatePassword(user, newPassword)
          .then(() => resolve(true))
          .catch((error) => reject(this._handleError(error)));
      } else {
        reject(new Error('No user is currently signed in.'));
      }
    });
  };

  /**
   * Change an user's account on firebase realtime database
   * @param {String} userId String with the unique id of an user
   * @param {String} accountId The account unique id the will be the user's new account
   * @returns {Promise<Boolean|String>} Promise that resolves true when the account is changed successfully or rejects the error message
   */
  changeUserAccount = (userId, accountId) => {
    return new Promise(async (resolve, reject) => {
      const db = getDatabase();
      const userRef = ref(db, `users/${userId}`);

      try {
        const snapshot = await get(userRef);
        const databaseValue = snapshot.val();

        if (databaseValue) {
          const updatedData = { ...databaseValue, account: accountId };
          await update(userRef, updatedData);
          resolve(true);
        } else {
          reject("This user doesn't exist");
        }
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   * Change an user's notifications settings off on firebase realtime database
   * @param {String} userId String with the unique id of an user
   * @returns {Promise<Boolean|String>} Promise that resolves true when the notifications are turned off successfully or rejects the error message
   */
  disableUserNotifications = (userId) => {
    return new Promise(async (resolve, reject) => {
      const db = getDatabase();
      const userRef = ref(db, `users/${userId}`);

      try {
        const snapshot = await get(userRef);
        const databaseValue = snapshot.val();

        if (databaseValue) {
          const updatedData = {
            ...databaseValue,
            emailNotification: {
              newTask: false,
              newComment: false,
              assignedToTask: false,
            },
          };

          await update(userRef, updatedData);
          resolve(true);
        } else {
          reject("This user doesn't exist");
        }
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   * Returns the current firebase authenticated user id. When the page is loading will return null!
   * @returns {(String|null)} String with the User's unique id that is currently authenticated
   */
  currentUser = () => {
    const auth = getAuth();
    return auth.currentUser;
  };

  /**
   * Access the firebase realtime database and checks for the user status, if it's different than activated it logs out the user and rejects with a message
   * @param {String} userId String with the unique id of an user
   * @returns {Promise<object|String>} Promise that resolves the user's data or rejects the error message
   */
  isUserAuth = (userId) => {
    return new Promise(async (resolve, reject) => {
      const db = getDatabase();
      const userRef = ref(db, 'users/' + userId);

      try {
        const snapshot = await get(userRef);
        const databaseValue = snapshot.val();

        if (databaseValue) {
          if (databaseValue.status === 'Activated') {
            resolve(databaseValue);
          } else {
            await this.logout();
            if (databaseValue.status === 'Pending') {
              reject("This user wasn't accepted by its company yet. Check Later!");
            } else {
              reject(
                'There is no user record corresponding to this identifier. The user may have been deleted.',
              );
            }
          }
        } else {
          reject('No user data found.');
        }
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   * Returns the realtime database name of the user from the id given
   * @param {String} userId String with the unique id of an user
   * @returns {Promise<object|String>} Promise that resolves the user's name or rejects the error message
   */
  userName = (userId) => {
    return new Promise(async (resolve, reject) => {
      const db = getDatabase();
      const userRef = ref(db, `users/${userId}`);

      try {
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
          const databaseValue = snapshot.val();
          resolve(databaseValue.name);
        } else {
          reject("This user doesn't exist");
        }
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   * Activates the user from the id given, updating its status and adding them to the team database if the status is not already activated
   * @param {String} userId String with the unique id of an user
   * @returns {Promise<object|String>} Promise that resolves true when the user is activated successfully or rejects the error message
   */
  activateUser = (userId) => {
    return new Promise(async (resolve, reject) => {
      const db = getDatabase();
      const userRef = ref(db, 'users/' + userId);

      try {
        const snapshot = await get(userRef);
        const databaseValue = snapshot.val();

        if (!databaseValue) {
          return reject("This user doesn't exist");
        }

        if (databaseValue.status === 'Activated') {
          return resolve('User already activated');
        }

        const newValue = { ...databaseValue, status: 'Activated' };

        await this.updateUser(newValue);
        await this.addTeamUser(databaseValue);

        resolve(true);
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   * Deactivates the user from the id given, updating its status and removing them from the team database if the status is not already deactivated
   * @param {String} userId String with the unique id of an user
   * @returns {Promise<object|String>} Promise that resolves true when the user is deactivated successfully or rejects the error message
   */
  deactivateUser = (userId) => {
    return new Promise(async (resolve, reject) => {
      const db = getDatabase();
      const userRef = ref(db, 'users/' + userId);

      try {
        const snapshot = await get(userRef);
        const databaseValue = snapshot.val();

        if (!databaseValue) {
          return reject("This user doesn't exist");
        }

        if (databaseValue.status === 'Deactivated') {
          return resolve('User already deactivated');
        }

        const newValue = { ...databaseValue, status: 'Deactivated' };

        await this.updateUser(newValue);
        await this.removeTeamUser(databaseValue);

        resolve(true);
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   * Returns the current id token for the current user
   * @returns {Promise<object|String>} Promise that resolves the user's id token or rejects the error message
   * */
  getIdToken = () => {
    return new Promise((resolve, reject) => {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        user
          .getIdToken()
          .then((idToken) => resolve(idToken))
          .catch((error) => reject(this._handleError(error)));
      }
    });
  };

  // ACCOUNT METHODS

  /**
   * Register the accounts data on the realtime database, register its account code and create its proper team and project data
   * @param {object} account Object with all the account's information
   * @returns {Promise<Boolean|String>} Promise that resolves true when all the accounts data is registered successfully or rejects the error message
   */
  registerAccount = (account) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const accountRef = ref(db, 'accounts/' + account.id);

      set(accountRef, {
        id: account.id,
        name: account.name,
        domain: account.domain,
        code: account.code,
        status: account.status,
        plan: account.plan,
        hasLogo: account.hasLogo,
        beginDate: account.beginDate,
        endDate: account.endDate,
        services: account.services,
        modules: account.modules,
        contractTime: account.contractTime,
        onetime: account.onetime,
        limits: account.limits,
        internalTeam: account.internalTeam,
        createdAt: serverTimestamp(),
      })
        .then(async () => {
          try {
            await this.registerCode(account.id, account.name, account.code);
            await this.registerTeam(account.id);
            await this.registerProject(account.id, 1, account.name);
            resolve(true);
          } catch (error) {
            reject(this._handleError(error));
          }
        })
        .catch((error) => reject(this._handleError(error)));
    });
  };

  /**
   * Returns an array with all the accounts stored on the firebase realtime database
   * @returns {Promise<object|String>} Promise that resolves an array with all accounts or rejects the error message
   */

  getAllAccounts = async () => {
    try {
      const db = getDatabase();
      const accountsRef = ref(db, 'accounts/');
      const snapshot = await get(accountsRef);
      return snapshot.val();
    } catch (error) {
      throw new Error(`Error fetching accounts: ${error.message}`);
    }
  };

  /**
   * Returns an account's value from the id given
   * @param {String} accountId String with the unique id of an account
   * @returns {Promise<object|String>} Promise that resolves an array with all accounts or rejects the error message
   */
  getAccount = (accountId) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const accountRef = ref(db, 'accounts/' + accountId);

      get(accountRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            resolve(snapshot.val());
          } else {
            reject("This account doesn't exist");
          }
        })
        .catch((error) => reject(this._handleError(error)));
    });
  };

  /**
   * Returns an account's value from the id given
   * @param {String} accountId String with the unique id of an account
   * @param {object} account Object with all the account's information
   * @returns {Promise<Boolean|String>} Promise that resolves true when the account is updated successfully or rejects the error message
   */
  updateAccount = (accountId, account) => {
    return new Promise((resolve, reject) => {
      try {
        const db = getDatabase();
        const accountRef = ref(db, `accounts/${accountId}`);

        update(accountRef, account)
          .then(() => resolve(true))
          .catch((error) => reject(this._handleError(error)));
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   * Deactivates an account and then removes its data from the firebase realtime database
   * @param {String} accountId String with the unique id of an account
   * @returns {Promise<Boolean|String>} Promise that resolves true when the account is removed successfully or rejects the error message
   */
  removeAccount = (accountId) => {
    return new Promise(async (resolve, reject) => {
      try {
        await this.deactivateAccount(accountId);

        const db = getDatabase();
        const accountRef = ref(db, `accounts/${accountId}`);

        await remove(accountRef);
        resolve(true);
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   * Returns a newly pushed key from the account path
   * @returns {String} String with a non used Unique Key on Firebase's Database
   */
  newAccountKey = () => {
    const db = getDatabase();
    const accountsRef = ref(db, 'accounts/');
    return push(accountsRef).key;
  };

  /**
   * Returns the realtime database name of the account from the id given
   * @param {String} accountId String with the unique id of an account
   * @returns {Promise<object|String>} Promise that resolves the account's name or rejects the error message
   */
  accountName = async (accountId) => {
    try {
      const db = getDatabase();
      const accountRef = ref(db, `accounts/${accountId}`);
      const snapshot = await get(accountRef);

      if (snapshot.exists()) {
        const databaseValue = snapshot.val();
        return databaseValue.name;
      } else {
        throw new Error("Account doesn't exist");
      }
    } catch (error) {
      throw new Error(`Error fetching account name: ${error.message}`);
    }
  };

  /**
   * Save the gDrive folder link on the account's database
   * @param {String} accountId Account's Unique Id
   * @param {Object} objlinks Object with the links to the gDrive folder
   * @returns
   */
  saveGDriveFolderLink = (accountId, objLinks) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const accountRef = ref(db, `accounts/${accountId}`);

      get(accountRef)
        .then((snapshot) => {
          const accountData = snapshot.val();

          if (!accountData.gDriveFolders) {
            accountData.gDriveFolders = '';
          }

          return update(accountRef, { gDriveFolders: objLinks });
        })
        .then(() => resolve(true))
        .catch((error) => reject(this._handleError(error)));
    });
  };

  /**
   * Get the gDrive folder link on the account's database
   * @param {String} accountId Account's Unique Id
   * @returns
   */
  getGDriveFolderLink = (accountId) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const folderRef = ref(db, `accounts/${accountId}/gDriveFolders/root`);

      get(folderRef)
        .then((snapshot) => {
          resolve(snapshot.val());
        })
        .catch((error) => reject(this._handleError(error)));
    });
  };

  /**
   * Get the gDrive content folder link on the account's database
   * @param {String} accountId Account's Unique Id
   * @returns
   */
  getGDriveFolders = (accountId) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const folderContentRef = ref(db, `accounts/${accountId}/gDriveFolders`);

      get(folderContentRef)
        .then((snapshot) => {
          resolve(snapshot.val());
        })
        .catch((error) => reject(this._handleError(error)));
    });
  };

  /**
   * Get CMS Auth data from the account's database
   * @param {String} accountId Account's Unique Id
   * @returns {Promise<Boolean|String>} Promise that resolves true when the data is stored successfully or rejects the error message
   * */
  getCmsAuth = (accountId) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const accountRef = ref(db, `accounts/${accountId}`);

      get(accountRef)
        .then((snapshot) => {
          const accountData = snapshot.val();

          if (!accountData.cmsAuth) {
            accountData.cmsAuth = {};
          }

          resolve(accountData.cmsAuth);
        })
        .catch((error) => reject(this._handleError(error)));
    });
  };

  /**
   * Delete cmsAuth from the account's database
   * @param {String} accountId Account's Unique Id
   * @returns
   * */
  deleteCmsAuth = (accountId) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const accountRef = ref(db, `accounts/${accountId}`);

      get(accountRef)
        .then((snapshot) => {
          const accountData = snapshot.val();

          if (!accountData.cmsAuth) {
            accountData.cmsAuth = {};
          }

          return update(accountRef, { cmsAuth: {} });
        })
        .then(() => resolve(true))
        .catch((error) => reject(this._handleError(error)));
    });
  };

  /**
   * Activates an account and register its code to the realtime database
   * @param {String} accountId String with the unique id of an account
   * @returns {Promise<Boolean|String>} Promise that resolves true when the account is activated successfully or rejects the error message
   */
  activateAccount = (accountId) => {
    return new Promise(async (resolve, reject) => {
      const db = getDatabase();
      const accountRef = ref(db, `accounts/${accountId}`);

      try {
        const snapshot = await get(accountRef);
        const databaseValue = snapshot.val();

        if (!databaseValue) {
          return reject("This account doesn't exist");
        }

        if (!databaseValue.status) {
          databaseValue.status = true;
          await this.updateAccount(accountId, databaseValue);
          await this.registerCode(accountId, databaseValue.name, databaseValue.code);
          resolve(true);
        } else {
          resolve('Account already activated');
        }
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   * Deactivates an account and removes its code from the realtime database
   * @param {String} accountId String with the unique id of an account
   * @returns {Promise<Boolean|String>} Promise that resolves true when the account is deactivated successfully or rejects the error message
   */
  deactivateAccount = (accountId) => {
    return new Promise(async (resolve, reject) => {
      const db = getDatabase();
      const accountRef = ref(db, `accounts/${accountId}`);

      try {
        const snapshot = await get(accountRef);
        const databaseValue = snapshot.val();

        // Checks if the account exists
        if (!databaseValue) {
          return reject("This account doesn't exist");
        }

        if (databaseValue.status) {
          databaseValue.status = false;
          await this.updateAccount(accountId, databaseValue);
          await this.removeCode(databaseValue.code);
          resolve(true);
        } else {
          resolve('Account already deactivated');
        }
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  // PROJECT METHODS

  /**
   * Registers the project info on the firebase realtime database
   * @param {String} accountId String with the unique id of an account
   * @param {String} projectName String with the name of the new project
   * @returns {Promise<Boolean|String>} Promise that resolves true when the data is stored successfully or rejects the error message
   */
  registerProject = (accountId, projectId, projectName) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const projectRef = ref(db, `projects/${accountId}/${projectId}`);

      set(projectRef, {
        id: projectId,
        name: projectName,
      })
        .then(() => resolve(true))
        .catch((error) => reject(this._handleError(error)));
    });
  };

  /**
   * Returns an array with all projects data from an account with the id given
   * @param {String} accountId String with the unique id of an account
   * @returns {Promise<object|String>} Promise that resolves an array with all the projects or rejects the error message
   */
  getAllProjects = (accountId) => {
    return new Promise(async (resolve, reject) => {
      const db = getDatabase();
      const projectsRef = ref(db, `projects/${accountId}`);

      try {
        const snapshot = await get(projectsRef);
        resolve(snapshot.val());
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   * Returns the realtime database name of the project from the id given
   * @param {String} accountId String with the unique id of an account
   * @param {String} projectId String with the unique id of a project
   * @returns {Promise<object|String>} Promise that resolves the project's name or rejects the error message
   */
  projectName = (accountId, projectId) => {
    return new Promise(async (resolve, reject) => {
      const db = getDatabase();
      const projectsRef = ref(db, `projects/${accountId}`);

      try {
        const snapshot = await get(projectsRef);
        const databaseValue = snapshot.val();

        if (databaseValue) {
          if (databaseValue.name) {
            resolve(databaseValue.name);
          } else if (databaseValue[projectId]) {
            resolve(databaseValue[projectId].name);
          } else {
            reject("This project doesn't exist");
          }
        } else {
          reject("This project doesn't exist");
        }
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  // TEAM METHODS

  /**
   * Registers the team info on the firebase realtime database
   * @param {String} accountId String with the unique id of an account
   * @returns {Promise<Boolean|String>} Promise that resolves true when the data is stored successfully or rejects the error message
   */
  registerTeam = (accountId) => {
    return new Promise(async (resolve, reject) => {
      const db = getDatabase();
      const teamRef = ref(db, `teams/${accountId}/initialTeamState`);

      try {
        await set(teamRef, {
          id: 'initialTeamState',
          name: 'initialTeamState',
        });
        resolve(true);
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   * Returns the value from the firebase realtime database from the specified team with the id given
   * @param {String} accountId String with the unique id of an account
   * @returns {Promise<object|String>} Promise that resolves the value of the specified team or rejects the error message
   */
  getTeam = (accountId) => {
    return new Promise(async (resolve, reject) => {
      const db = getDatabase();
      const teamRef = ref(db, 'teams/' + accountId);

      try {
        const snapshot = await get(teamRef);
        if (snapshot.exists()) {
          resolve(snapshot.val());
        } else {
          resolve(null);
        }
      } catch (error) {
        reject(`Error fetching team data: ${error.message}`);
      }
    });
  };

  /**
   * Returns the value from the firebase realtime database from the specified internal team with the id given
   * @param {String} accountId String with the unique id of an account
   * @returns {Promise<object|String>} Promise that resolves the value of the specified internal team or rejects the error message
   */
  getInternalTeam = (accountId) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const internalTeamRef = ref(db, `accounts/${accountId}/internalTeam`);

      get(internalTeamRef)
        .then((snapshot) => {
          resolve(snapshot.val());
        })
        .catch((error) => {
          reject(this._handleError(error));
        });
    });
  };

  /**
   * Returns the value from the firebase realtime database from the specified approval team with the id given
   * @param {String} accountId String with the unique id of an account
   * @returns {Promise<object|String>} Promise that resolves the value of the specified approval team or rejects the error message
   **/
  getApprovalTeam = (accountId) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const approvalTeamRef = ref(db, `accounts/${accountId}/approvalTeam`);

      get(approvalTeamRef)
        .then((snapshot) => {
          resolve(snapshot.val());
        })
        .catch((error) => {
          reject(this._handleError(error));
        });
    });
  };

  /**
   * Add an user on the team database with the id given
   * @param {String} account String with the unique id of an account
   * @param {String} id String with the unique id of an user
   * @param {String} name String with the name of an user
   * @returns {Promise<object|String>} Promise that resolves true when the user is successfully added on the team or rejects the error message
   */
  addTeamUser = ({ account, id, name }) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const teamRef = ref(db, `teams/${account}/` + id);

      set(teamRef, {
        id,
        name,
      }).then(
        () => resolve(true),
        (error) => reject(this._handleError(error)),
      );
    });
  };

  /**
   * Remove an user from the team database with the id given
   * @param {String} account String with the unique id of an account
   * @param {String} id String with the unique id of an user
   * @returns {Promise<object|String>} Promise that resolves true when the user is successfully removed from the team or rejects the error message
   */
  removeTeamUser = ({ account, id }) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const teamRef = ref(db, `teams/${account}/` + id);

      remove(teamRef)
        .then(() => resolve(true))
        .catch((error) => reject(this._handleError(error)));
    });
  };

  // CODE METHODS

  /**
   * Registers the code info on the firebase realtime database
   * @param {String} accountId String with the unique id of an account
   * @param {String} accountName String with the name of an account
   * @param {String} accountCode String with the code of an account
   * @returns {Promise<Boolean|String>} Promise that resolves true when the data is stored successfully or rejects the error message
   */
  registerCode = (accountId, accountName, accountCode) => {
    return new Promise(async (resolve, reject) => {
      const db = getDatabase();
      const codeRef = ref(db, `codes/${accountCode}`);

      try {
        await set(codeRef, {
          accountId: accountId,
          accountName: accountName,
        });
        resolve(true);
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   * Change an account's code on firebase realtime database by deactivating it and activating with the updated code
   * @param {String} accountId String with the unique id of an account
   * @param {String} newCode  String with the new code of the account
   * @returns {Promise<Boolean|String>} Promise that resolves true when the account is changed successfully or rejects the error message
   */
  changeAccountCode = (accountId, newCode) => {
    return new Promise(async (resolve, reject) => {
      const db = getDatabase();
      const accountRef = ref(db, `accounts/${accountId}`);

      try {
        const snapshot = await get(accountRef);
        const databaseValue = snapshot.val();

        if (databaseValue.code !== newCode) {
          if (databaseValue.status) {
            await this.deactivateAccount(accountId);
            databaseValue.code = newCode;
            await this.updateAccount(accountId, databaseValue);
            await this.activateAccount(accountId);
            resolve(true);
          } else {
            databaseValue.code = newCode;
            await this.updateAccount(accountId, databaseValue);
            resolve(true);
          }
        } else {
          reject(`${newCode} already is the code for this account`);
        }
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   * Remove a code from the firebase realtime database
   * @param {String} code String with the code of an account
   * @returns {Promise<Boolean|String>} Promise that resolves true when the code is successfully removed from the team or rejects the error message
   */
  removeCode = (code) => {
    return new Promise(async (resolve, reject) => {
      const db = getDatabase();
      const codeRef = ref(db, `codes/${code}`);

      try {
        await remove(codeRef);
        resolve(true);
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   * Returns the realtime database account id from the code given
   * @param {String} code String with the code of an account
   * @returns {Promise<String>} Promise that resolves the account's unique id or rejects the error message
   */
  codeAccount = (code) => {
    return new Promise(async (resolve, reject) => {
      const db = getDatabase();
      const codeRef = ref(db, `codes/${code}`);

      try {
        const snapshot = await get(codeRef);
        const databaseValue = snapshot.val();
        if (databaseValue) {
          resolve(databaseValue.accountId);
        } else {
          reject("Code doesn't exist");
        }
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   * Returns the realtime database code from the account given
   * @param {String} accountId String with the unique id of an account
   * @returns {Promise<String>} Promise that resolves the account's code or rejects the error message
   */
  accountCode = (accountId) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const codeRef = ref(db, `accounts/${accountId}/code`);

      get(codeRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            resolve(snapshot.val());
          } else {
            reject(`${accountId} doesn't exist`);
          }
        })
        .catch((error) => reject(this._handleError(error)));
    });
  };

  // STORAGE METHODS

  /**
   * Stores one file to the given firebase storage path and return its snapshot
   * @param {object} file File to be stored
   * @param {String} path String with the path of the firebase storage to store the files in
   * @returns {Promise<object>} Promise that resolves the snapshot of the put function on the storage path
   */
  storeSingleFile = (file, path) => {
    const generateRandomSuffix = (length = 12) => {
      return Math.random().toString().slice(-length);
    };

    return new Promise((resolve, reject) => {
      const storage = getStorage();
      const randomSuffix = generateRandomSuffix();
      const fileNameWithSuffix = `${file.name.replace(/\.[^/.]+$/, '')}_${randomSuffix}${file.name.slice(file.name.lastIndexOf('.'))}`;
      const fileRef = refStorage(storage, `${path}/${fileNameWithSuffix}`);
      const uploadTask = uploadBytesResumable(fileRef, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          console.log(
            'Upload in progress:',
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100 + '% completed',
          );
        },
        (error) => {
          reject(this._handleError(error));
        },
        () => {
          resolve(uploadTask.snapshot);
        },
      );
    });
  };

  /**
   * Stores one or more files to the given firebase storage path
   * @param {object} files File or Array of Files to be stored
   * @param {String} path String with the path of the firebase storage to store the files in
   * @returns {Promise<Boolean|String>} Promise that resolves true when the files are successfully stored or rejects the error message
   */
  storeFiles = (files, path) => {
    return new Promise(async (resolve, reject) => {
      const storage = getStorage();

      try {
        if (Array.isArray(files)) {
          // Armazena arquivos se 'files' for um array
          await Promise.all(
            files.map(async (file) => {
              const fileRef = refStorage(storage, `${path}/${file.name}`);
              await uploadBytes(fileRef, file);
            }),
          );
        } else {
          const fileRef = refStorage(storage, `${path}/${files.name}`);
          await uploadBytes(fileRef, files);
        }
        resolve(true);
      } catch (error) {
        reject(`Error storing files: ${error.message}`);
      }
    });
  };

  /**
   * Stores one file on the users bucket to them use it as photo
   * @param {object} file File to be stored as an user's photo
   * @param {String} userId String with the unique id of an user
   * @returns {Promise<Boolean|String>} Promise that resolves true when the photo is successfully stored or rejects the error message
   */
  storeUserPhoto = (file, userId) => {
    return new Promise(async (resolve, reject) => {
      const fileExtension = file.name.split('.').pop();
      const myNewFile = new File([file], `pfp.${fileExtension}`, {
        type: file.type,
      });
      await this.storeFiles(myNewFile, `${userId}/profilePic`);
      resolve(true);
    });
  };

  storeCourseBanner = (file, accountId, courseId) => {
    return new Promise(async (resolve, reject) => {
      const fileExtension = file.name.split('.').pop();
      const myNewFile = new File([file], `course-banner.${fileExtension}`, {
        type: file.type,
      });
      await this.storeFiles(myNewFile, `${accountId}/courses/${courseId}`);
      resolve(true);
    });
  };

  /**
   * Stores one file on the accounts bucket to them use it as logo
   * @param {object} file File to be stored as an account's logo
   * @param {String} accountId String with the unique id of an account
   * @returns {Promise<Boolean|String>} Promise that resolves true when the logo is successfully stored or rejects the error message
   */
  storeAccountLogo = (file, accountId) => {
    return new Promise(async (resolve, reject) => {
      const fileExtension = file.name?.split('.').pop();
      const myNewFile = new File([file], `logo.${fileExtension}`, {
        type: file.type,
      });
      await this.storeFiles(myNewFile, `${accountId}/logo`);
      resolve(true);
    });
  };

  /**
   * Remove all files on the user's bucket and store one file on the user bucket
   * @param {object} newPhoto File or Blob object that will be the new user's photo
   * @param {String} userId String with the unique id of an user
   * @returns {Promise<Boolean|String>} Promise that resolves true when the user's photo is updated successfully or rejects the error message
   */
  updateUserPhoto = (newPhoto, userId) => {
    return new Promise(async (resolve, reject) => {
      const fileList = await this.listAllFiles(`${userId}/profilePic/`);
      fileList.forEach(async (file) => {
        this.removeFile(`${userId}/profilePic/${file.name}`);
      });
      if (!newPhoto) {
        return resolve(true);
      }
      await this.storeUserPhoto(newPhoto, userId);
      resolve(true);
    });
  };

  updateCourseBanner = (newPhoto, accountId, courseId) => {
    return new Promise(async (resolve, reject) => {
      const fileList = await this.listAllFiles(`${accountId}/courses/${courseId}`);
      for (let file of fileList) {
        await this.removeFile(`${accountId}/courses/${courseId}/${file.name}`);
      }
      if (!newPhoto) {
        return resolve(true);
      }
      await this.storeCourseBanner(newPhoto, accountId, courseId);
      resolve(true);
    });
  };

  /**
   * Remove all files on the account's bucket and store one file on the accounts bucket
   * @param {object} newPhoto File or Blob object that will be the new account's logo
   * @param {String} accountId String with the unique id of an account
   * @returns {Promise<Boolean|String>} Promise that resolves true when the account's logo is updated successfully or rejects the error message
   */
  updateAccountLogo = (newPhoto, accountId) => {
    return new Promise(async (resolve, reject) => {
      const fileList = await this.listAllFiles(`${accountId}/profilePic/`);
      fileList.forEach(async (file) => {
        await this.removeFile(`${accountId}/profilePic/${file.name}`);
      });
      await this.storeAccountLogo(newPhoto, accountId);
      resolve(true);
    });
  };

  /**
   * Lists all the files of a bucket from the given path and return an array with all of its metadata
   * @param {String} path String with the bucket path to be listed
   * @returns {Promise<object|String>} Promise that resolves an array with the metadata of all the bucket's files or rejects the error message
   */
  listAllFiles = (path) => {
    return new Promise((resolve, reject) => {
      const storage = getStorage();
      const pathRef = refStorage(storage, path);

      listAll(pathRef)
        .then(async (res) => {
          if (res.items.length === 0) {
            reject('No files on this bucket');
            return;
          }
          const fileMetadata = await Promise.all(
            res.items.map(async (itemRef) => await getMetadata(itemRef)),
          );
          resolve(fileMetadata);
        })
        .catch((error) => {
          reject(`Error fetching files: ${error.message}`);
        });
    });
  };

  /**
   * Returns the download URL of the stored file on the given path
   * @param {String} path String with the bucket path of a file on Firebase Storage
   * @returns {Promise<String>} Promise that resolves an string with the download url or rejects the error message
   */
  getFileURL = (path) => {
    return new Promise(async (resolve, reject) => {
      const storage = getStorage();
      const fileRef = refStorage(storage, path);

      try {
        const url = await getDownloadURL(fileRef);
        resolve(url);
      } catch (error) {
        reject(`Error getting file URL: ${error.message}`);
      }
    });
  };

  /**
   * Returns the file object of the stored file on the given path
   * @param {String} path String with the bucket path of a file on Firebase Storage
   * @returns {Promise<object|String>} Promise that resolves blob object of the file of the file or rejects the error message
   */
  getFileObj = (path) => {
    return new Promise((resolve, reject) => {
      this.getFileURL(path).then(
        (url) => {
          const xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          xhr.onload = () => {
            const blob = xhr.response;
            resolve(blob);
          };
          xhr.open('GET', url);
          xhr.send();
        },
        (error) => reject(this._handleError(error)),
      );
    });
  };

  /**
   * Remove the file on the path given
   * @param {String} path String with the bucket path of a file on Firebase Storage
   * @returns {Promise<object|String>} Promise that resolves true when the file is removed successfully or rejects the error message
   */
  removeFile = (path) => {
    return new Promise((resolve, reject) => {
      const storage = getStorage();
      const fileRef = refStorage(storage, path);

      deleteObject(fileRef)
        .then(() => resolve(true))
        .catch((error) => reject(this._handleError(error)));
    });
  };

  /**
   * Remove the unused files of markdown descriptions from uncreated tasks
   * @param {String} accountId String with the account Id
   * @param {String} taskId String with the task Id
   * @returns {Promise<Boolean|String>} Promise that resolves true when the unused files are removed successfully or rejects the error message
   */
  removeUnusedMDFiles = ({ accountId, id }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const storage = getStorage();
        const filePath = `${accountId}/${id}/md-description/`;
        const filesArr = await this.listAllFiles(filePath);

        if (filesArr.length === 0) {
          resolve(true);
          return;
        }

        const deletePromises = filesArr.map((file) => {
          const fileRef = refStorage(storage, file.fullPath);
          return deleteObject(fileRef);
        });

        await Promise.all(deletePromises);
        resolve(true);
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  // COMMENTS METHODS

  /**
   * Registers the comment data on the firebase realtime database
   * @param {object} comment Object with all comment's information, including account, project and task Id
   * @returns {Promise<Boolean|String>} Promise that resolves true when the data is stored successfully or rejects the error message
   */
  registerComment = (comment) => {
    return new Promise((resolve, reject) => {
      try {
        const db = getDatabase();
        const commentRef = ref(
          db,
          `comments/${comment.accountId}/${comment.columnId}/${comment.id}`,
        );

        set(commentRef, {
          id: comment.id,
          by: comment.by,
          content: comment.content,
          createdAt: serverTimestamp(),
        })
          .then(() => resolve(true))
          .catch((error) => reject(this._handleError(error)));
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   * Returns the value from the firebase realtime database from the specified comment object with the necessary Ids given
   * @param {String} clientId String with the user's Firebase Auth Unique Id
   * @param {String} taskId String with the task's Unique Id
   * @param {String} commentId String with the comment's Unique Id
   * @returns {Promise<object|String>} Promise that resolves the value of the specified comment or rejects the error message
   */
  getComment = ({ accountId, taskId, commentId }) => {
    return new Promise((resolve, reject) => {
      try {
        const db = getDatabase();
        const commentRef = ref(db, `comments/${accountId}/${taskId}/${commentId}`);

        get(commentRef)
          .then((snapshot) => {
            const databaseValue = snapshot.val();
            if (databaseValue) {
              resolve(databaseValue);
            } else {
              reject("This comment doesn't exist");
            }
          })
          .catch((error) => reject(this._handleError(error)));
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   * Returns an array from the firebase realtime database with all the comments of a specified account's project task
   * @param {String} clientId String with the user's Firebase Auth Unique Id
   * @param {String} taskId String with the task's Unique Id
   * @returns {Promise<object|String>} Promise that resolves an array of all the account project's task comments or rejects the error message
   */
  getAllComments = ({ accountId, taskId }) => {
    return new Promise((resolve, reject) => {
      try {
        const db = getDatabase();
        const commentsRef = ref(db, `comments/${accountId}/${taskId}`);

        get(commentsRef)
          .then((snapshot) => {
            const databaseValue = snapshot.val();
            resolve(databaseValue);
          })
          .catch((error) => reject(this._handleError(error)));
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   * Updates the comment data on the firebase realtime database removing the unnecessary keys. It needs to include every key value!
   * @param {object} comment Object with all comment's information, including account, project and task Id
   * @returns {Promise<Boolean|String>} Promise that resolves true when the comment is updated successfully or rejects the error message
   */
  updateComment = (comment) => {
    return new Promise((resolve, reject) => {
      try {
        const db = getDatabase();
        const updates = {};

        const pureComment = JSON.parse(JSON.stringify(comment));

        delete pureComment.commentId;
        delete pureComment.accountId;
        delete pureComment.projectId;
        delete pureComment.columnId;
        delete pureComment.taskId;
        delete pureComment.self;
        delete pureComment.dropdown;
        delete pureComment.byName;

        updates[`comments/${comment.accountId}/${comment.columnId}/${comment.id}`] = pureComment;

        const updatesRef = ref(db);

        update(updatesRef, updates)
          .then(() => resolve(true))
          .catch((error) => reject(this._handleError(error)));
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   * Removes the comment data from the firebase realtime database
   * @param {String} accountId Account's Unique Id
   * @param {String} taskId Task's Unique Id
   * @param {String} commentId comment's Unique Id
   * @returns {Promise<Boolean|String>} Promise that resolves true when the data is removed successfully or rejects the error message
   */
  removeComment = ({ accountId, columnId, commentId }) => {
    return new Promise((resolve, reject) => {
      try {
        const db = getDatabase();
        const commentRef = ref(db, `comments/${accountId}/${columnId}/${commentId}`);

        remove(commentRef)
          .then(() => resolve(true))
          .catch((error) => reject(this._handleError(error)));
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   * Returns a newly pushed key from the comments path
   * @param {String} accountId Account's Unique Id
   * @param {String} taskId Task's Unique Id
   * @returns {String} String with a non used Unique Key on Firebase's Database
   */
  newCommentKey = ({ accountId, id }) => {
    const db = getDatabase();
    const commentsRef = ref(db, `comments/${accountId}/${id}`);
    const newCommentRef = push(commentsRef);
    return newCommentRef.key;
  };

  // CHECKLIST METHODS

  /**
   * Registers the check data on the firebase realtime database
   * @param {object} check Object with all check's information, including account, project and task Id
   * @returns {Promise<Boolean|String>} Promise that resolves true when the data is stored successfully or rejects the error message
   */
  registerCheck = (check) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const checkRef = ref(db, `checklists/${check.accountId}/${check.taskId}/${check.id}`);

      set(checkRef, {
        id: check.id,
        checked: check.checked,
        content: check.content,
        position: check.position,
      })
        .then(() => resolve(true))
        .catch((error) => reject(this._handleError(error)));
    });
  };

  /**
   * Registers the check data on the firebase realtime database
   * @param {object} check Object with all check's information, including account, project and task Id
   * @returns {Promise<Boolean|String>} Promise that resolves true when the data is stored successfully or rejects the error message
   */
  registerChecklist = (check) => {
    return new Promise(async (resolve, reject) => {
      const taskToBeUpdated = await this.getTask({
        ...check,
        clientId: check.accountId,
      });

      const updatedTask = {
        ...taskToBeUpdated,
        accountId: check.accountId,
        clientId: check.accountId,
        taskId: check.taskId,
        hasChecklist: true,
      };

      await this.updateTask(updatedTask);
      resolve(true);
    });
  };

  /**
   * Returns the value from the firebase realtime database from the specified check object with the necessary Ids given
   * @param {String} clientId String with the user's Firebase Auth Unique Id
   * @param {String} taskId String with the task's Unique Id
   * @param {String} checkId String with the check's Unique Id
   * @returns {Promise<object|String>} Promise that resolves the value of the specified check or rejects the error message
   */
  getCheck = ({ accountId, taskId, checkId }) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const checkRef = ref(db, `checklists/${accountId}/${taskId}/${checkId}`);

      get(checkRef)
        .then((snapshot) => {
          const databaseValue = snapshot.val();
          if (databaseValue) {
            resolve(databaseValue);
          } else {
            reject("This Check doesn't exist");
          }
        })
        .catch((error) => reject(this._handleError(error)));
    });
  };

  /**
   * Returns an array from the firebase realtime database with all the checks of a specified account's project task
   * @param {String} clientId String with the user's Firebase Auth Unique Id
   * @param {String} taskId String with the task's Unique Id
   * @returns {Promise<object|String>} Promise that resolves an array of all the account project's task check or rejects the error message
   */
  getChecklist = ({ accountId, taskId }) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const checklistRef = ref(db, 'checklists/' + accountId + '/' + taskId);

      try {
        get(checklistRef)
          .then((snapshot) => {
            resolve(snapshot.val());
          })
          .catch((error) => {
            reject(this._handleError(error));
          });
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   * Updates the check data on the firebase realtime database removing the unnecessary keys. It needs to include every key value!
   * @param {object} check Object with all check's information, including account, project and task Id
   * @returns {Promise<Boolean|String>} Promise that resolves true when the check is updated successfully or rejects the error message
   */
  updateCheck = (check) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const updates = {};

      const pureCheck = { ...check };
      delete pureCheck.accountId;
      delete pureCheck.projectId;
      delete pureCheck.taskId;

      updates[`checklists/${check.accountId}/${check.taskId}/${check.id}`] = pureCheck;

      update(ref(db), updates)
        .then(() => resolve(true))
        .catch((error) => reject(this._handleError(error)));
    });
  };

  /**
   * Removes the check data from the firebase realtime database
   * @param {String} accountId Account's Unique Id
   * @param {String} taskId Task's Unique Id
   * @param {String} checkId Check's Unique Id
   * @returns {Promise<Boolean|String>} Promise that resolves true when the data is removed successfully or rejects the error message
   */
  removeCheck = ({ accountId, taskId, checkId }) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const checkRef = ref(db, `checklists/${accountId}/${taskId}/${checkId}`);

      remove(checkRef)
        .then(() => resolve(true))
        .catch((error) => reject(this._handleError(error)));
    });
  };

  /**
   * Removes the check data from the firebase realtime database
   * @param {String} accountId Account's Unique Id
   * @param {String} taskId Task's Unique Id
   * @returns {Promise<Boolean|String>} Promise that resolves true when the data is removed successfully or rejects the error message
   */
  removeChecklist = ({ accountId, taskId }) => {
    return new Promise(async (resolve, reject) => {
      const db = getDatabase();
      const checklistRef = ref(db, `checklists/${accountId}/${taskId}`);

      try {
        await remove(checklistRef);

        const taskToBeUpdated = await this.getTask({
          clientId: accountId,
          accountId,
          taskId,
        });

        const updatedTask = {
          ...taskToBeUpdated,
          clientId: accountId,
          accountId,
          taskId,
          hasChecklist: false,
        };

        await this.updateTask(updatedTask);
        resolve(true);
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   * Returns a newly pushed key from the checklists path
   * @param {String} accountId Account's Unique Id
   * @param {String} taskId Task's Unique Id
   * @returns {String} String with a non used Unique Key on Firebase's Database
   */
  newCheckKey = ({ accountId, taskId }) => {
    const db = getDatabase();
    const checklistRef = ref(db, `checklists/${accountId}/${taskId}`);
    return push(checklistRef).key;
  };

  /**
   * Returns the last position of the specified checklist in the project's task
   * @param {String} accountId Account's Unique Id
   * @param {String} taskId Task's Unique Id
   * @returns {Promise<Number|String>} Promise that resolves the last position of the checklist or rejects the error message
   */
  getLastCheckPosition = ({ accountId, taskId }) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const checklistRef = ref(db, `checklists/${accountId}/${taskId}`);

      get(checklistRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const databaseValue = snapshot.val();
            let higher = -1;

            Object.entries(databaseValue).forEach(([checkId, check]) => {
              if (check.position > higher) {
                higher = check.position;
              }
            });

            resolve(++higher);
          } else {
            resolve(0);
          }
        })
        .catch((error) => reject(this._handleError(error)));
    });
  };

  /**
   * Returns a string with the stats from the checklist of the task given on the format "(checked/total)"
   * @param {String} accountId String with the user's Firebase Auth Unique Id
   * @param {String} taskId String with the task's Unique Id
   * @returns {Promise<String>} Promise that resolves the value of the specified stats or rejects the error message
   */
  getChecklistStats = ({ accountId, taskId }) => {
    return new Promise(async (resolve, reject) => {
      const checklistObj = await this.getChecklist({
        accountId,
        taskId,
      });

      const checklistArr = checklistObj ? Object.entries(checklistObj) : [];

      const total = checklistArr.length;
      let checked = 0;
      checklistArr.forEach(([checkId, check]) => {
        if (check.checked) {
          checked++;
        }
      });

      resolve(`(${checked}/${total})`);
    });
  };

  // MEETING METHODS

  /**
   * Registers the check data on the firebase realtime database
   * @param {object} meeting Object with all check's information, including account, project and task Id
   * @returns {Promise<Boolean|String>} Promise that resolves true when the data is stored successfully or rejects the error message
   */
  registerMeeting = (meeting) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const meetingRef = ref(db, `meetings/${meeting.accountId}/${meeting.id}`);

      set(meetingRef, {
        id: meeting.id,
        agenda: meeting.agenda,
        date: meeting.date,
        minutes: meeting.minutes,
        description: meeting.description,
        videoLink: meeting.videoLink,
        participants: meeting.participants,
        relatedTasks: meeting.relatedTasks,
        steps: meeting.steps,
      })
        .then(() => resolve(true))
        .catch((error) => reject(this._handleError(error)));
    });
  };

  /**
   * Returns the value from the firebase realtime database from the specified task object with the necessary Ids given
   * @param {object} task Object with all task's information, including user, project and task Id
   * @returns {Promise<object|String>} Promise that resolves the value of the specified task or rejects the error message
   */
  getMeeting = (meeting) => {
    const db = getDatabase();
    const meetingRef = ref(db, `meetings/${meeting.accountId}/${meeting.id}`);

    return new Promise(async (resolve, reject) => {
      try {
        const snapshot = await get(meetingRef);
        const databaseValue = snapshot.val();
        if (databaseValue) {
          resolve(databaseValue);
        } else {
          reject("This meeting doesn't exist");
        }
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   * Returns an array from the firebase realtime database with all the tasks of a specified account and project
   * @param {String} accountId String with the user's Firebase Auth Unique Id
   * @returns {Promise<object|String>} Promise that resolves an array of all the project's tasks or rejects the error message
   */
  getAllMeetings = (accountId) => {
    return new Promise(async (resolve, reject) => {
      const db = getDatabase();
      const meetingsRef = ref(db, `meetings/${accountId}`);

      try {
        const snapshot = await get(meetingsRef);
        const databaseValue = snapshot.val();
        resolve(databaseValue);
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   * Updates the task data on the firebase realtime database removing the unnecessary keys. It needs to include every key value!
   * @param {object} meeting Object with all task's information, including user, project and task Id
   * @returns {Promise<Boolean|String>} Promise that resolves true when the task is updated successfully or rejects the error message
   */
  updateMeeting = (meeting) => {
    const db = getDatabase();
    const updates = {};
    const pureMeeting = JSON.parse(JSON.stringify(meeting));

    delete pureMeeting.accountId;
    delete pureMeeting.projectId;

    updates[`/meetings/${meeting.accountId}/${meeting.id}`] = pureMeeting;

    return new Promise((resolve, reject) => {
      update(ref(db), updates)
        .then(() => resolve(true))
        .catch((error) => reject(this._handleError(error)));
    });
  };

  /**
   * Returns a newly pushed key from the tasks path
   * @param {String} accountId Account's Unique Id
   * @returns {String} String with a non used Unique Key on Firebase's Database
   */
  newMeetingKey = async (accountId) => {
    const db = getDatabase();
    const meetingsRef = ref(db, 'meetings/' + accountId);

    try {
      const newMeetingRef = push(meetingsRef);
      return newMeetingRef.key;
    } catch (error) {
      throw new Error(`Error generating new meeting key: ${error.message}`);
    }
  };

  // AUTH METHODS

  /**
   * Log in authenticated firebase user with given email and password
   * @param {String} email String with the user's email
   * @param {String} password String with the user's password
   * @returns {Promise<String>} Promise that resolves the user's id when succesfully logged in or rejects the error message
   */
  loginUser = (email, password) => {
    const auth = getAuth();

    return new Promise((resolve, reject) => {
      signInWithEmailAndPassword(auth, email, password)
        .then(() => resolve(auth.currentUser))
        .catch((error) => reject(this._handleError(error)));
    });
  };

  /**
   * Log out authenticated firebase user
   * @returns {Promise<String>} Promise that resolves true when succesfully logged out or rejects the error message
   */
  logout = () => {
    const auth = getAuth();

    return new Promise((resolve, reject) => {
      signOut(auth)
        .then(() => resolve(true))
        .catch((error) => reject(this._handleError(error)));
    });
  };

  /**
   * Sends an email to reset the password of an user
   * @param {String} email String with the user's email
   * @returns {Promise<String>} Promise that resolves true when the email is sucessfully sent or rejects the error message
   */
  forgetPassword = (email) => {
    const auth = getAuth();

    return new Promise((resolve, reject) => {
      sendPasswordResetEmail(auth, email, {
        url: `${window.location.protocol}//${window.location.host}/login`,
      })
        .then(() => resolve(true))
        .catch((error) => reject(this._handleError(error)));
    });
  };

  /**
   * Sets a local storage key with the given user value to mantain the log in information
   * @param {object} user Object with all the user's firebase information
   */
  setLoggeedInUser = (user) => {
    localStorage.setItem('authUser', JSON.stringify(user));
  };

  /**
   * Gets the object with the users firebase information from local storage
   * @returns {object} Object with all the user's firebase information
   */
  getAuthenticatedUser = () => {
    if (!localStorage.getItem('authUser')) return null;
    return JSON.parse(localStorage.getItem('authUser'));
  };

  // CONTENT METHODS

  getFirebaseContent = (accountId, contentId) => {
    const db = getDatabase();
    const contentRef = ref(db, `contents/${accountId}/${contentId}`);

    return new Promise(async (resolve, reject) => {
      try {
        const snapshot = await get(contentRef);
        const databaseValue = snapshot.val();
        resolve(databaseValue);
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  setFirebaseContent = (content) => {
    const db = getDatabase();
    const contentRef = ref(db, `contents/${content.accountId}/${content.id}`);

    return new Promise((resolve, reject) => {
      set(contentRef, {
        type: content.type,
        size: content.size,
        segment: content.segment,
        voiceTone: content.voiceTone,
        goal: content.goal,
        primaryKeyword: content.primaryKeyword,
        secondaryKeyword: content.secondaryKeyword,
        titleSuggestion: content.titleSuggestion,
        topics: content.topics,
        references: content.references,
        observations: content.observations,
        domain: content.domain,
        assignedTo: content.assignedTo,
        expectedDate: content.expectedDate,
        publicationDate: content.publicationDate,
        status: content.status,
        column: content.column,
        position: content.position,
      }).then(
        () => resolve(true),
        (error) => reject(this._handleError(error)),
      );
    });
  };

  updateFirebaseContent = (content) => {
    const db = getDatabase();
    const contentRef = ref(db, `contents/${content.accountId}/${content.id}`);

    return new Promise((resolve, reject) => {
      const normalizedContent = cloneDeep(content);
      delete normalizedContent.accountId;
      delete normalizedContent.id;

      update(contentRef, normalizedContent).then(
        () => resolve(true),
        (error) => reject(this._handleError(error)),
      );
    });
  };

  removeFirebaseContent = (accountId, id) => {
    const db = getDatabase();
    const contentRef = ref(db, `contents/${accountId}/${id}`);

    return new Promise((resolve, reject) => {
      remove(contentRef).then(
        () => resolve(true),
        (error) => reject(this._handleError(error)),
      );
    });
  };

  getLastContentPosition = (accountId, column) => {
    const db = getDatabase();
    const contentsRef = ref(db, `contents/${accountId}/`);

    return new Promise(async (resolve, reject) => {
      try {
        const snapshot = await get(contentsRef);
        const databaseValue = snapshot.val();

        if (databaseValue) {
          let higher = -1;
          Object.entries(databaseValue).forEach(([_, content]) => {
            if (content.column === column && content.position > higher) {
              higher = content.position;
            }
          });
          resolve(++higher);
        } else {
          resolve(0);
        }
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  registerContent = (content) => {
    return new Promise(async (resolve, reject) => {
      try {
        await this.setFirebaseContent(content);
        axios.post('https://us-central1-ectools-9318d.cloudfunctions.net/contentStats', {
          accountId: content.accountId,
          contentId: content.id,
        });
        resolve(true);
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   * Returns a newly pushed key from the contents path
   * @param {String} accountId Account's Unique Id
   * @returns {String} String with a non used Unique Key on Firebase's Database
   */
  newContentKey = (accountId) => {
    return new Promise((resolve, reject) => {
      try {
        const db = getDatabase();
        const contentRef = ref(db, `contents/${accountId}`);
        const newKey = push(contentRef).key;

        resolve(newKey);
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  // GUIDELINE METHODS

  /**
   * Returns the specific guideline
   * @param {String} accountId Account's Unique Id
   * @param {String} guidelineId Guideline's Unique Id
   * @returns {Object} Guideline
   */
  getFirebaseGuideline = (accountId, guidelineId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const db = getDatabase();
        const guidelineRef = ref(db, `guidelines/${accountId}/${guidelineId}`);

        get(guidelineRef)
          .then((snapshot) => {
            resolve(snapshot.val());
          })
          .catch((error) => {
            reject(this._handleError(error));
          });
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   * Returns all guidelines
   * @param {String} accountId Account's Unique Id
   * @returns {Array} Guidelines
   */
  getAllFirebaseGuidelines = (accountId) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const guidelinesRef = ref(db, `guidelines/${accountId}`);

      get(guidelinesRef)
        .then((snapshot) => {
          resolve(snapshot.val());
        })
        .catch((error) => {
          reject(this._handleError(error));
        });
    });
  };

  /**
   * Delete a guideline
   * @param {String} accountId Account's Unique Id
   * @param {String} guidelineId Guideline's Unique Id
   * @returns {Boolean} True if the guideline was deleted
   * */
  deleteGuideline = (accountId, guidelineId) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const guidelineRef = ref(db, `guidelines/${accountId}/${guidelineId}`);

      remove(guidelineRef)
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(this._handleError(error));
        });
    });
  };

  /**
   * Return the guideline number by production date
   * @param {String} accountId Account's Unique Id
   * @param {String} guidelineId ID of the guideline to find the order
   * @param {Object} productionDate Production Date
   * @returns {Object} Guideline number and total count
   */
  getGuidelineNumberByProductionDate = (accountId, guidelineId, productionDate) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const guidelinesRef = ref(db, `guidelines/${accountId}`);

      get(guidelinesRef)
        .then((snapshot) => {
          if (!snapshot.exists()) {
            resolve({
              totalCount: 0,
              orderNumber: null,
            });
            return;
          }

          const databaseValue = snapshot.val();
          const guidelines = Object.entries(databaseValue).map(([id, value]) => ({
            id,
            ...value,
          }));

          const guidelinesByProductionYear = guidelines.filter(
            (guideline) =>
              parseInt(guideline.productionDate?.year) === parseInt(productionDate?.year),
          );

          const guidelinesByProductionMonth = guidelinesByProductionYear.filter(
            (guideline) =>
              parseInt(guideline.productionDate?.month) === parseInt(productionDate?.month),
          );

          guidelinesByProductionMonth.sort((a, b) => a.createdAt - b.createdAt);

          const orderNumber = guidelinesByProductionMonth.findIndex(
            (guideline) => guideline.id === guidelineId,
          );

          resolve({
            totalCount: guidelinesByProductionMonth.length,
            orderNumber: orderNumber === -1 ? null : orderNumber + 1,
          });
        })
        .catch((error) => reject(this._handleError(error)));
    });
  };

  /**
   * Return all guidelines with their ID, production date, and order
   * @param {String} accountId Account's Unique Id
   * @returns {Array} Array of guidelines with ID, production date, and order
   */
  getAllGuidelinesNumbers = (accountId) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const guidelinesRef = ref(db, `guidelines/${accountId}`);

      get(guidelinesRef)
        .then((snapshot) => {
          const databaseValue = snapshot.val();
          if (databaseValue) {
            const guidelines = Object.entries(databaseValue).map(([id, value]) => ({
              id,
              ...value,
            }));

            // Group guidelines by production date
            const groupedGuidelines = guidelines.reduce((acc, guideline) => {
              const productionYear = guideline.productionDate?.year;
              const productionMonth = guideline.productionDate?.month;

              if (productionYear && productionMonth) {
                const key = `${productionYear}-${productionMonth}`;
                if (!acc[key]) {
                  acc[key] = [];
                }
                acc[key].push(guideline);
              }
              return acc;
            }, {});

            // Sort each group by createdAt and assign order number
            const result = [];
            Object.keys(groupedGuidelines).forEach((key) => {
              groupedGuidelines[key].sort((a, b) => a.createdAt - b.createdAt);
              groupedGuidelines[key].forEach((guideline, index) => {
                result.push({
                  id: guideline.id,
                  productionDate: {
                    month: guideline.productionDate.month,
                    year: guideline.productionDate.year,
                  },
                  order: index + 1,
                });
              });
            });

            resolve(result);
          } else {
            resolve([]);
          }
        })
        .catch((error) => reject(this._handleError(error)));
    });
  };

  /**
   * Send guideline to approval
   * @param {String} accountId Account's Unique Id
   * @param {String} guidelineId Guideline's Unique Id
   * @returns
   */
  sendGuidelineToApproval = (accountId, guidelineId) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const guidelineRef = ref(db, `guidelines/${accountId}/${guidelineId}`);

      get(guidelineRef)
        .then((snapshot) => {
          const databaseValue = snapshot.val();
          if (!databaseValue.responsibleToApproval) {
            return reject('You must select at least one person to approve');
          }

          update(guidelineRef, { column: 'GUIDELINE_APPROVAL' })
            .then(() => resolve(true))
            .catch((error) => reject(this._handleError(error)));
        })
        .catch((error) => reject(this._handleError(error)));
    });
  };

  /**
   * Send guideline to content structure
   * @param {String} accountId Account's Unique Id
   * @param {String} guidelineId Guideline's Unique Id
   * @param {String} reason Reason to approve
   * @returns
   */
  approveGuidelineToContentStructure = (accountId, guidelineId, reason) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const guidelineRef = ref(db, `guidelines/${accountId}/${guidelineId}`);

      get(guidelineRef)
        .then((snapshot) => {
          const guidelineData = snapshot.val();
          const statusReason = guidelineData.statusReason || '';

          return update(guidelineRef, {
            column: 'CONTENT_STRUCTURE',
            statusReason: reason || statusReason,
          });
        })
        .then(() => resolve(true))
        .catch((error) => reject(this._handleError(error)));
    });
  };

  /**
   * Send guideline to internal approval
   * @param {String} accountId Account's Unique Id
   * @param {String} guidelineId Guideline's Unique Id
   * @returns
   */
  sendGuidelineToInternalApproval = (accountId, guidelineId) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const guidelineRef = ref(db, `guidelines/${accountId}/${guidelineId}`);

      update(guidelineRef, {
        column: 'INTERNAL_APPROVAL',
      })
        .then(() => resolve(true))
        .catch((error) => reject(this._handleError(error)));
    });
  };

  /**
   * Send guideline to content approval
   * @param {String} accountId Account's Unique Id
   * @param {String} guidelineId Guideline's Unique Id
   * @returns
   */
  sendGuidelineToContentApproval = (accountId, guidelineId) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const guidelineRef = ref(db, `guidelines/${accountId}/${guidelineId}`);

      update(guidelineRef, {
        column: 'CONTENT_APPROVAL',
      })
        .then(() => resolve(true))
        .catch((error) => reject(this._handleError(error)));
    });
  };

  /**
   * Send guideline to content review
   * @param {String} accountId Account's Unique Id
   * @param {String} guidelineId Guideline's Unique Id
   * @returns
   */
  sendGuidelineToContentReview = (accountId, guidelineId) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const guidelineRef = ref(db, `guidelines/${accountId}/${guidelineId}`);

      update(guidelineRef, {
        column: 'CONTENT_REVIEW',
      })
        .then(() => resolve(true))
        .catch((error) => reject(this._handleError(error)));
    });
  };

  /**
   * Create content by structure
   * @param {String} accountId Account's Unique Id
   * @param {String} guidelineId Guideline's Unique Id
   * @returns
   */
  createContentByStructure = (accountId, guidelineId) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const guidelineRef = ref(db, `guidelines/${accountId}/${guidelineId}`);

      get(guidelineRef)
        .then((snapshot) => {
          const guidelineData = snapshot.val();

          if (guidelineData && guidelineData.structure) {
            let content = '';

            Object.keys(guidelineData.structure).forEach((key) => {
              const contentKey = guidelineData.structure[key]?.content;

              if (contentKey) {
                content += contentKey + '\n\n';
              }
            });

            update(guidelineRef, { content })
              .then(() => resolve(true))
              .catch((error) => reject(this._handleError(error)));
          } else {
            resolve(false);
          }
        })
        .catch((error) => reject(this._handleError(error)));
    });
  };

  /**
   * Deny a guideline
   * @param {String} accountId Account's Unique Id
   * @param {String} guidelineId Guideline's Unique Id
   * @param {String} reason Reason to deny
   * @returns
   */
  denyGuideline = (accountId, guidelineId, reason) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const guidelineRef = ref(db, `guidelines/${accountId}/${guidelineId}`);

      get(guidelineRef)
        .then((snapshot) => {
          const guidelineData = snapshot.val();

          if (guidelineData) {
            const updates = {
              denied: true,
              statusReason: guidelineData.statusReason || '',
            };

            if (reason) {
              updates.statusReason = reason;
            }

            update(guidelineRef, updates)
              .then(() => resolve(true))
              .catch((error) => reject(this._handleError(error)));
          } else {
            resolve(false);
          }
        })
        .catch((error) => reject(this._handleError(error)));
    });
  };

  /**
   * Archive a guideline
   * @param {String} accountId Account's Unique Id
   * @param {String} guidelineId Guideline's Unique Id
   * @param {String} reason Reason to deny
   * @returns
   */
  archiveGuideline = (accountId, guidelineId, reason) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const guidelineRef = ref(db, `guidelines/${accountId}/${guidelineId}`);

      get(guidelineRef)
        .then((snapshot) => {
          const guidelineData = snapshot.val();

          if (guidelineData) {
            const updates = {
              archived: true,
            };

            if (reason) {
              updates.archiveReason = reason;
            } else if (guidelineData.archiveReason === undefined) {
              updates.archiveReason = '';
            }

            update(guidelineRef, updates)
              .then(() => resolve(true))
              .catch((error) => reject(this._handleError(error)));
          } else {
            resolve(false);
          }
        })
        .catch((error) => reject(this._handleError(error)));
    });
  };

  /**
   * Delete a topic from a guideline and reorder the remaining topics
   * @param {String} accountId Account's Unique Id
   * @param {String} guidelineId Guideline's Unique Id
   * @param {Number} topicIndex Topic's index
   * @returns
   */
  deleteGuidelineTopic = (accountId, guidelineId, topicIndex) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const guidelineRef = ref(db, `guidelines/${accountId}/${guidelineId}/structure`);

      get(guidelineRef)
        .then((snapshot) => {
          const structure = snapshot.val() || {};

          delete structure[topicIndex];

          const updatedStructure = {};
          let newIndex = 0;

          Object.keys(structure).forEach((index) => {
            updatedStructure[newIndex++] = structure[index];
          });

          return set(guidelineRef, updatedStructure);
        })
        .then(() => resolve(true))
        .catch((error) => reject(this._handleError(error)));
    });
  };

  /**
   * Edit topic by index
   * @param {String} accountId Account's Unique Id
   * @param {String} guidelineId Guideline's Unique Id
   * @param {Number} topicIndex Topic's index
   * @param {String} newTopic New topic
   * @returns
   */
  editGuidelineTopic = (accountId, guidelineId, topicIndex, newTopic) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const guidelineRef = ref(
        db,
        `guidelines/${accountId}/${guidelineId}/structure/${topicIndex}`,
      );

      get(guidelineRef)
        .then(() => {
          return update(guidelineRef, {
            topic: newTopic,
          });
        })
        .then(() => resolve(true))
        .catch((error) => reject(this._handleError(error)));
    });
  };

  /**
   * New topic to a guideline
   * @param {String} accountId Account's Unique Id
   * @param {String} guidelineId Guideline's Unique Id
   * @param {String} newTopic New topic
   * @returns
   */
  newGuidelineTopic = (accountId, guidelineId, newTopic) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const guidelineRef = ref(db, `guidelines/${accountId}/${guidelineId}/structure`);

      get(guidelineRef)
        .then((snapshot) => {
          const structure = snapshot.val();
          const newIndex = structure ? Object.keys(structure).length : 0;

          return update(guidelineRef, {
            [newIndex]: {
              topic: newTopic,
            },
          });
        })
        .then(() => resolve(true))
        .catch((error) => reject(this._handleError(error)));
    });
  };

  /**
   * Add a googleQuestions to a topic
   * @param {String} accountId Account's Unique Id
   * @param {String} guidelineId Guideline's Unique Id
   * @param {String} question Question to add
   * @returns
   */
  addQuestionToTopics = (accountId, guidelineId, question) => {
    return new Promise(async (resolve, reject) => {
      try {
        const db = getDatabase();
        const topicsRef = ref(db, `guidelines/${accountId}/${guidelineId}/structure`);

        const snapshot = await get(topicsRef);
        const existingStructure = snapshot.val();

        const newQuestionObject = {
          topic: question,
        };

        const updatedStructure = existingStructure
          ? [...existingStructure, newQuestionObject]
          : [newQuestionObject];

        await set(topicsRef, updatedStructure);
        resolve(true);
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   * Update the order of the topics
   * @param {String} accountId Account's Unique Id
   * @param {String} guidelineId Guideline's Unique Id
   * @param {Array} newOrder Array with the new order
   * @returns
   * */
  updateStructureTopicsOrder = (accountId, guidelineId, newOrder) => {
    return new Promise(async (resolve, reject) => {
      try {
        const db = getDatabase();
        const topicsRef = ref(db, `guidelines/${accountId}/${guidelineId}/structure`);

        await update(topicsRef, newOrder);

        resolve(true);
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   * Set the responsibleToApproval
   * @param {String} accountId Account's Unique Id
   * @param {String} guidelineId Guideline's Unique Id
   * @param {Array} responsibleToApproval Array with those responsibleToApproval
   * @returns
   */
  setResponsibleToApproval = (accountId, guidelineId, responsibleToApproval) => {
    return new Promise(async (resolve, reject) => {
      try {
        const db = getDatabase();
        const guidelineRef = ref(db, `guidelines/${accountId}/${guidelineId}`);

        const snapshot = await get(guidelineRef);

        await update(guidelineRef, { responsibleToApproval });

        resolve(true);
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   *
   * @param {String} accountId Account's Unique Id
   * @param {String} guidelineId Guideline's Unique Id
   * @param {Array} assignedTo Array with those assignedTo
   * @returns
   */
  setAssignedTo = (accountId, guidelineId, assignedTo) => {
    return new Promise(async (resolve, reject) => {
      try {
        const db = getDatabase();
        const guidelineRef = ref(db, `guidelines/${accountId}/${guidelineId}`);

        await update(guidelineRef, { assignedTo });

        resolve(true);
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   * Adding a id to guideline property
   * @param {String} accountId Account's Unique Id
   * @param {String} guidelineId Guideline's Unique Id
   * @returns
   */
  addGuidelineId = (accountId, guidelineId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const db = getDatabase();
        const guidelineRef = ref(db, `guidelines/${accountId}/${guidelineId}`);

        const snapshot = await get(guidelineRef);
        const guidelineData = snapshot.val();

        if (!guidelineData?.id) {
          await update(guidelineRef, { id: guidelineId });
        }

        resolve(true);
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   *
   * @param {String} accountId Account's Unique Id
   * @param {String} guidelineId Guideline's Unique Id
   * @param {number} topicIndex Topic's index
   * @param {String} heading Heading to change
   * @returns
   */

  changeHeadingTopic = (accountId, guidelineId, topicIndex, heading) => {
    return new Promise(async (resolve, reject) => {
      try {
        const db = getDatabase();
        const guidelineRef = ref(
          db,
          `guidelines/${accountId}/${guidelineId}/structure/${topicIndex}`,
        );

        const snapshot = await get(guidelineRef);
        const guidelineData = snapshot.val() || {};

        if (!guidelineData.heading) {
          guidelineData.heading = '';
        }

        await update(guidelineRef, {
          heading: heading,
        });

        resolve(true);
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   *
   * @param {String} accountId Account's Unique Id
   * @param {String} guidelineId Guideline's Unique Id
   * @param {number} topicIndex Topic's index
   * @param {String} prompt Prompt to change
   * @returns
   */
  changePromptTopic = (accountId, guidelineId, topicIndex, prompt) => {
    return new Promise(async (resolve, reject) => {
      try {
        const db = getDatabase();
        const guidelineRef = ref(
          db,
          `guidelines/${accountId}/${guidelineId}/structure/${topicIndex}`,
        );

        const snapshot = await get(guidelineRef);
        const guidelineData = snapshot.val() || {};

        if (!guidelineData.prompt) {
          guidelineData.prompt = '';
        }

        await update(guidelineRef, {
          prompt: prompt,
        });

        resolve(true);
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   *
   * @param {String} accountId Account's Unique Id
   * @param {String} guidelineId Guideline's Unique Id
   * @param {number} topicIndex Topic's index
   * @param {number} size Size to change
   * @returns
   */
  changeSizeTopic = (accountId, guidelineId, topicIndex, size) => {
    return new Promise(async (resolve, reject) => {
      try {
        const db = getDatabase();
        const guidelineRef = ref(
          db,
          `guidelines/${accountId}/${guidelineId}/structure/${topicIndex}`,
        );

        const snapshot = await get(guidelineRef);
        const guidelineData = snapshot.val() || {};

        if (!guidelineData.size) {
          guidelineData.size = '';
        }

        await update(guidelineRef, {
          size: size,
        });

        resolve(true);
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   *
   * @param {String} accountId Account's Unique Id
   * @param {String} guidelineId Guideline's Unique Id
   * @param {number} topicIndex Topic's index
   * @param {*} content Content to change
   * @returns
   */
  changeContentTopic = (accountId, guidelineId, topicIndex, content) => {
    return new Promise(async (resolve, reject) => {
      try {
        const db = getDatabase();
        const guidelineRef = ref(
          db,
          `guidelines/${accountId}/${guidelineId}/structure/${topicIndex}`,
        );

        const snapshot = await get(guidelineRef);
        const guidelineData = snapshot.val() || {};

        if (!guidelineData.content) {
          guidelineData.content = '';
        }

        await update(guidelineRef, {
          content: content,
        });

        resolve(true);
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   *
   * @param {String} accountId Account's Unique Id
   * @param {String} guidelineId Guideline's Unique Id
   * @param {*} content Content to change
   * @returns
   */
  changeContent = (accountId, guidelineId, content) => {
    return new Promise(async (resolve, reject) => {
      try {
        const db = getDatabase();
        const guidelineRef = ref(db, `guidelines/${accountId}/${guidelineId}`);

        const snapshot = await get(guidelineRef);
        const guidelineData = snapshot.val() || {};

        if (!guidelineData.content) {
          guidelineData.content = '';
        }

        await update(guidelineRef, {
          content: content,
        });

        resolve(true);
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   *
   * @param {String} accountId Account's Unique Id
   * @param {String} guidelineId Guideline's Unique Id
   * @param {String} title New title
   * @returns
   */
  updateGuidelineTitle = (accountId, guidelineId, title) => {
    return new Promise(async (resolve, reject) => {
      try {
        const db = getDatabase();
        const guidelineRef = ref(db, `guidelines/${accountId}/${guidelineId}`);

        const snapshot = await get(guidelineRef);
        const guidelineData = snapshot.val() || {};

        if (!guidelineData.title) {
          guidelineData.title = '';
        }

        await update(guidelineRef, {
          title: title,
        });

        resolve(true);
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   *
   * @param {String} accountId Account's Unique Id
   * @param {String} guidelineId Guideline's Unique Id
   * @param {String} userId User's Unique Id
   * @param {String} commentInput Comment to add
   * @returns
   */
  newCommentGuideline = (accountId, guidelineId, userId, commentInput) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const guidelineRef = ref(db, `guidelines/${accountId}/${guidelineId}`);

      get(guidelineRef)
        .then((snapshot) => {
          const guidelineData = snapshot.val() || {};

          if (!guidelineData.comments) {
            guidelineData.comments = {};
          }

          const commentsRef = ref(db, `guidelines/${accountId}/${guidelineId}/comments`);
          const newCommentKey = push(commentsRef).key;

          const newComment = {
            by: userId,
            type: 'comment',
            content: commentInput,
            createdAt: serverTimestamp(),
          };

          const updates = {};
          updates[`comments/${newCommentKey}`] = newComment;

          update(guidelineRef, updates)
            .then(() => resolve(true))
            .catch((error) => reject(this._handleError(error)));
        })
        .catch((error) => reject(this._handleError(error)));
    });
  };

  /**
   *
   * @param {String} accountId Account's Unique Id
   * @param {String} guidelineId Guideline's Unique Id
   * @param {String} commentId Comment's Unique Id
   * @param {String} commentInput New comment
   * @returns
   */
  editCommentGuideline = (accountId, guidelineId, commentId, commentInput) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const commentRef = ref(db, `guidelines/${accountId}/${guidelineId}/comments/${commentId}`);

      get(commentRef)
        .then((snapshot) => {
          const existingComment = snapshot.val()?.content;

          if (existingComment !== commentInput) {
            return update(commentRef, {
              content: commentInput,
            });
          } else {
            return Promise.resolve(true);
          }
        })
        .then(() => resolve(true))
        .catch((error) => reject(this._handleError(error)));
    });
  };

  /**
   *
   * @param {String} accountId Account's Unique Id
   * @param {String} guidelineId Guideline's Unique Id
   * @param {String} commentId Comment's Unique Id
   * @returns
   */
  deleteCommentGuideline = (accountId, guidelineId, commentId) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const commentRef = ref(db, `guidelines/${accountId}/${guidelineId}/comments/${commentId}`);

      remove(commentRef)
        .then(() => resolve(true))
        .catch((error) => reject(this._handleError(error)));
    });
  };

  /**
   *
   * @param {String} accountId Account's Unique Id
   * @param {String} guidelineId Guideline's Unique Id
   * @param {String} userId User's Unique Id
   * @param {String} taskInput Task to add
   * @returns
   */
  newTaskGuideline = (accountId, guidelineId, userId, taskInput) => {
    return new Promise(async (resolve, reject) => {
      try {
        const db = getDatabase();
        const guidelineRef = ref(db, `guidelines/${accountId}/${guidelineId}`);

        const snapshot = await guidelineRef.once('value');
        const guidelineData = snapshot.val();

        if (!guidelineData.comments) {
          guidelineData.comments = {};
        }

        const commentsRef = ref(db, `guidelines/${accountId}/${guidelineId}/comments`);
        const newCommentRef = push(commentsRef);

        const newComment = {
          by: userId,
          type: 'task',
          done: false,
          content: taskInput,
          createdAt: serverTimestamp(),
        };

        const updates = {
          [`comments/${newCommentRef.key}`]: newComment,
        };

        await update(guidelineRef, updates);
        resolve(true);
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   * Set done task
   * @param {String} accountId Account's Unique Id
   * @param {String} guidelineId Guideline's Unique Id
   * @param {String} commentId Comment's Unique Id
   * @returns
   */
  setDoneTaskGuideline = (accountId, guidelineId, commentId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const db = getDatabase();
        const commentRef = ref(db, `guidelines/${accountId}/${guidelineId}/comments/${commentId}`);

        await update(commentRef, {
          done: true,
        });

        resolve(true);
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   *
   * @param {String} accountId Account's Unique Id
   * @param {String} guidelineId Guideline's Unique Id
   * @returns {Object}
   */
  getCommentsGuideline = (accountId, guidelineId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const db = getDatabase();
        const commentsRef = ref(db, `guidelines/${accountId}/${guidelineId}/comments`);

        const snapshot = await get(commentsRef);
        const databaseValue = snapshot.val();

        resolve(databaseValue);
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   *
   * @param {String} accountId Account's Unique Id
   * @param {String} guidelineId Guideline's Unique Id
   * @param {String} url Url to save
   * @returns
   */
  savePublishedUrlContent = (accountId, guidelineId, url) => {
    return new Promise(async (resolve, reject) => {
      try {
        const db = getDatabase();
        const guidelineRef = ref(db, `guidelines/${accountId}/${guidelineId}`);

        const snapshot = await get(guidelineRef);

        await update(guidelineRef, {
          publishedUrl: url,
        });

        resolve(true);
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   * Save google doc Id
   * @param {String} accountId Account's Unique Id
   * @param {String} guidelineId Guideline's Unique Id
   * @param {String} googleDocId Google Doc Id to save
   * @returns
   * */
  saveGoogleDocLink = (accountId, guidelineId, googleDocId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const db = getDatabase();
        const guidelineRef = ref(db, `guidelines/${accountId}/${guidelineId}`);
        const snapshot = await get(guidelineRef);
        const guidelineData = snapshot.val() || {};

        if (!guidelineData.googleDocId) {
          guidelineData.googleDocId = '';
        }
        await update(guidelineRef, { googleDocId });
        resolve(true);
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   * Save google doc Id delivery
   * @param {String} accountId Account's Unique Id
   * @param {String} guidelineId Guideline's Unique Id
   * @param {String} googleDocIdDelivery Google Doc Id to save
   * @returns
   * */
  saveGoogleDocLinkDelivery = (accountId, guidelineId, googleDocIdDelivery) => {
    return new Promise(async (resolve, reject) => {
      try {
        const db = getDatabase();
        const guidelineRef = ref(db, `guidelines/${accountId}/${guidelineId}`);
        const snapshot = await get(guidelineRef);
        const guidelineData = snapshot.val() || {};

        if (!guidelineData.googleDocIdDelivery) {
          guidelineData.googleDocIdDelivery = '';
        }
        await update(guidelineRef, { googleDocIdDelivery });
        resolve(true);
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  saveCmsGuidelineLink = (accountId, guidelineId, link) => {
    return new Promise(async (resolve, reject) => {
      try {
        const db = getDatabase();
        const guidelineRef = ref(db, `guidelines/${accountId}/${guidelineId}`);
        const snapshot = await get(guidelineRef);
        const guidelineData = snapshot.val() || {};

        if (!guidelineData.cmsLink) {
          guidelineData.cmsLink = '';
        }
        await update(guidelineRef, { cmsLink: link });
        resolve(true);
      } catch (error) {
        reject(this._handleErrorleError(error));
      }
    });
  };

  /**
   * Get published URL content from the database.
   * @param {String} accountId - Account's Unique Id.
   * @param {String} guidelineId - Guideline's Unique Id.
   * @returns {Promise<String>} - A Promise that resolves with the published URL.
   */
  getPublishedUrlContent = (accountId, guidelineId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const db = getDatabase();
        const guidelineRef = ref(db, `guidelines/${accountId}/${guidelineId}`);

        const snapshot = await get(guidelineRef);
        const guidelineData = snapshot.val();
        const publishedUrl = guidelineData ? guidelineData.publishedUrl || '' : '';

        resolve(publishedUrl);
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   * Save the last position of the guideline metadata
   * @param {String} accountId Account's Unique Id
   * @param {String} columnName Column name to save the last position
   * @param {Number} lastPosition Last position to save
   * @returns {Promise<Boolean>} A Promise that resolves with a boolean value.
   * */
  saveGuidelineMetadataLastPosition = (accountId, columnName, lastPosition) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const guidelineRef = ref(db, `guidelines/${accountId}/metadata/lastPosition`);

      get(guidelineRef)
        .then((snapshot) => {
          if (!snapshot.exists()) {
            return set(guidelineRef, { [columnName]: lastPosition });
          } else {
            return update(guidelineRef, { [columnName]: lastPosition });
          }
        })
        .then(() => resolve(true))
        .catch((error) => reject(_handleError(error)));
    });
  };

  /**
   * Save the last count of the guideline metadata
   * @param {String} accountId Account's Unique Id
   * @param {String} dateKey Date key to save the last count
   * @param {Number} number Last count to save
   * @returns {Promise<Boolean>} A Promise that resolves with a boolean value.
   * */
  saveGuidelineMetadataLastCount = (accountId, dateKey, number) => {
    return new Promise(async (resolve, reject) => {
      try {
        const db = getDatabase();
        const guidelineRef = ref(db, `guidelines/${accountId}/metadata/lastCount`);

        const snapshot = await get(guidelineRef);

        if (!snapshot.exists()) {
          await set(guidelineRef, { [dateKey]: number });
        } else {
          await update(guidelineRef, { [dateKey]: number });
        }

        resolve(true);
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   * Get the last position of the guideline metadata by column name
   * @param {String} accountId Account's Unique Id
   * @param {String} columnName Column name to get the last position
   * @returns {Promise<Number>} A Promise that resolves with the last position.
   * */
  getGuidelineMetadataLastPosition = (accountId, columnName) => {
    return new Promise(async (resolve, reject) => {
      try {
        const db = getDatabase();
        const guidelineRef = ref(db, `guidelines/${accountId}/metadata/lastPosition`);

        const snapshot = await get(guidelineRef);
        const lastPosition = snapshot.exists() ? snapshot.val()[columnName] || 0 : 0;

        resolve(lastPosition);
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  // LESSON METHODS
  getFirebaseLesson = (accountId, lessonId) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const lessonRef = ref(db, `lessons/${accountId}/${lessonId}`);

      get(lessonRef)
        .then((snapshot) => {
          const databaseValue = snapshot.val();
          resolve(databaseValue);
        })
        .catch((error) => {
          reject(this._handleError(error));
        });
    });
  };

  getAllFirebaseLessons = (accountId, adminStatus) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const lessonsRef = ref(db, 'lessons/');

      get(lessonsRef)
        .then((snapshot) => {
          const databaseValue = snapshot.val();
          const formattedValue = {};

          if (databaseValue) {
            Object.entries(databaseValue).forEach(([account, lessons]) => {
              if (adminStatus !== 'Super Admin' && account !== accountId) return;

              Object.entries(lessons).forEach(([lessonId, lessonObj]) => {
                formattedValue[lessonId] = { ...lessonObj, account };
              });
            });
          }

          resolve(formattedValue);
        })
        .catch((error) => {
          reject(this._handleError(error));
        });
    });
  };

  setFirebaseLesson = (lesson) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const lessonRef = ref(db, `lessons/${lesson.accountId}/${lesson.id}`);

      set(lessonRef, {
        durationMins: lesson.durationMins,
        name: lesson.name,
        videoLink: lesson.videoLink,
        public: lesson.public,
        teachers: lesson.teachers,
        description: lesson.description,
        allowQuestions: lesson.allowQuestions,
        applyTest: lesson.applyTest,
        test: lesson.test,
        archived: false,
        createdAt: serverTimestamp(),
      })
        .then(() => resolve(true))
        .catch((error) => reject(this._handleError(error)));
    });
  };

  updateFirebaseLesson = (lesson) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const finalLesson = cloneDeep(lesson);
      delete finalLesson.accountId;
      delete finalLesson.id;

      const lessonRef = ref(db, `lessons/${lesson.accountId}/${lesson.id}`);

      update(lessonRef, {
        ...finalLesson,
      })
        .then(() => resolve(true))
        .catch((error) => reject(this._handleError(error)));
    });
  };

  removeFirebaseLesson = (accountId, id) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const lessonRef = ref(db, `lessons/${accountId}/${id}`);

      remove(lessonRef)
        .then(() => resolve(true))
        .catch((error) => reject(this._handleError(error)));
    });
  };

  newFirebaseKey = (path, accountId) => {
    const db = getDatabase();
    const newRef = ref(db, `${path}${accountId}`);
    return push(newRef).key;
  };

  // COURSE METHODS
  getFirebaseCourse = (accountId, courseId) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const courseRef = ref(db, `courses/${accountId}/${courseId}`);

      get(courseRef)
        .then((snapshot) => {
          const databaseValue = snapshot.val();
          resolve(databaseValue);
        })
        .catch((error) => reject(this._handleError(error)));
    });
  };

  getAllFirebaseCourses = (accountId, adminStatus) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const coursesRef = ref(db, 'courses/');

      get(coursesRef)
        .then((snapshot) => {
          const databaseValue = snapshot.val();
          const formattedValue = {};

          Object.entries(databaseValue ?? {}).forEach(([account, courses]) => {
            Object.entries(courses).forEach(([courseId, courseObj]) => {
              if (
                ((courseObj.status === 'published' ||
                  (courseObj.status === 'scheduled' && courseObj.soon)) &&
                  (courseObj.showOtherUsers || account === accountId)) ||
                adminStatus === 'Super Admin'
              ) {
                Object.assign(formattedValue, {
                  [courseId]: { ...courseObj, account },
                });
              }
            });
          });

          resolve(formattedValue);
        })
        .catch((error) => reject(this._handleError(error)));
    });
  };

  getAllAccountFirebaseCourses = (accountId, adminStatus) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const coursesRef = ref(db, 'courses/' + accountId);

      get(coursesRef)
        .then((snapshot) => {
          const databaseValue = snapshot.val();
          const formattedValue = {};

          Object.entries(databaseValue ?? {}).forEach(([courseId, courseObj]) => {
            if (
              courseObj.status === 'published' ||
              (courseObj.status === 'scheduled' && courseObj.soon) ||
              adminStatus === 'Super Admin'
            ) {
              Object.assign(formattedValue, { [courseId]: courseObj });
            }
          });

          resolve(formattedValue);
        })
        .catch((error) => reject(this._handleError(error)));
    });
  };

  setFirebaseCourse = (course) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const courseRef = ref(db, `courses/${course.accountId}/${course.id}`);

      set(courseRef, {
        name: course.name,
        teachers: course.teachers,
        modules: course.modules,
        description: course.description,
        clientFree: course.clientFree,
        freeTrial: course.freeTrial,
        freeTrialDays: course.freeTrialDays,
        showOtherUsers: course.showOtherUsers,
        availability: course.availability,
        releaseDate: course.releaseDate,
        soon: course.soon,
        certificateLock: course.certificateLock,
        certificateDays: course.certificateDays,
        status: course.status,
        createdAt: ServerValue.TIMESTAMP,
      })
        .then(() => resolve(true))
        .catch((error) => reject(this._handleError(error)));
    });
  };

  updateFirebaseCourse = (course) => {
    return new Promise((resolve, reject) => {
      const finalLesson = cloneDeep(course);
      delete finalLesson.accountParam;
      delete finalLesson.id;

      const db = getDatabase();
      const courseRef = ref(db, `courses/${course.accountParam}/${course.id}`);

      update(courseRef, finalLesson)
        .then(() => resolve(true))
        .catch((error) => reject(this._handleError(error)));
    });
  };

  // TEST METHODS

  getFirebaseTest = (accountId, testId) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const testRef = ref(db, `tests/${accountId}/${testId}`);

      get(testRef)
        .then((snapshot) => {
          const databaseValue = snapshot.val();
          resolve(databaseValue);
        })
        .catch((error) => reject(this._handleError(error)));
    });
  };

  getAllFirebaseTests = (accountId) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const testsRef = ref(db, `tests/${accountId}`);

      get(testsRef)
        .then((snapshot) => {
          const databaseValue = snapshot.val();
          resolve(databaseValue);
        })
        .catch((error) => reject(this._handleError(error)));
    });
  };

  setFirebaseTest = (test) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const testRef = ref(db, `tests/${test.accountId}/${test.id}`);

      set(testRef, {
        name: test.name,
        passPercent: test.passPercent,
        percent: test.percent,
        scrambleQuestions: test.scrambleQuestions,
        answerKey: test.answerKey,
        questions: test.questions,
        createdAt: new Date().toISOString(),
      })
        .then(() => resolve(true))
        .catch((error) => reject(this._handleError(error)));
    });
  };

  updateFirebaseTest = (test) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const testRef = ref(db, `tests/${test.accountId}/${test.id}`);

      const finalTest = cloneDeep(test);
      delete finalTest.accountId;
      delete finalTest.id;

      update(testRef, finalTest)
        .then(() => resolve(true))
        .catch((error) => reject(this._handleError(error)));
    });
  };

  removeFirebaseTest = (accountId, id) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const testRef = ref(db, `tests/${accountId}/${id}`);

      remove(testRef)
        .then(() => resolve(true))
        .catch((error) => reject(this._handleError(error)));
    });
  };

  // LIBRARY METHODS
  setFirebaseLibraryCourse = (userId, courseId, courseObj) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const courseRef = ref(db, `course-library/${userId}/${courseId}`);

      set(courseRef, {
        account: courseObj.account,
        status: courseObj.status,
        lastLesson: courseObj.lastLesson,
        progress: courseObj.progress,
        tests: courseObj.tests,
      })
        .then(() => resolve(true))
        .catch((error) => reject(this._handleError(error)));
    });
  };

  getFirebaseLibraryCourse = (userId, courseId) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const courseRef = ref(db, `course-library/${userId}/${courseId}`);

      get(courseRef)
        .then((snapshot) => {
          const databaseValue = snapshot.val();
          resolve(databaseValue);
        })
        .catch((error) => {
          reject(this._handleError(error));
        });
    });
  };

  getAllFirebaseLibraryCourse = (userId) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const libraryRef = ref(db, `course-library/${userId}`);

      get(libraryRef)
        .then((snapshot) => {
          const databaseValue = snapshot.val();
          resolve(databaseValue);
        })
        .catch((error) => {
          reject(this._handleError(error));
        });
    });
  };

  updateFirebaseLibraryCourse = (libraryObj) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const updates = {};
      const pureLibCourse = JSON.parse(JSON.stringify(libraryObj));

      // Remove unnecessary fields to lighten the library courses on Firebase
      delete pureLibCourse.userId;
      delete pureLibCourse.courseId;

      Object.entries(pureLibCourse).forEach(([path, value]) => {
        updates[`course-library/${libraryObj.userId}/${libraryObj.courseId}/${path}`] = value;
      });

      update(ref(db), updates)
        .then(() => resolve(true))
        .catch((error) => reject(this._handleError(error)));
    });
  };

  removeFirebaseLibraryCourse = (userId, courseId) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const courseRef = ref(db, `course-library/${userId}/${courseId}`);

      remove(courseRef)
        .then(() => resolve(true))
        .catch((error) => reject(this._handleError(error)));
    });
  };

  // CERTIFICATE METHODS
  getFirebaseCertificate = (certificateCode) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const certificatesRef = ref(db, 'certificates/');

      get(certificatesRef)
        .then((snapshot) => {
          const databaseValue = snapshot.val();
          if (!databaseValue) return resolve(null);

          for (const certificateObj of Object.values(databaseValue)) {
            if (certificateObj.code.includes(certificateCode)) {
              return resolve(certificateObj);
            }
          }
          resolve(null);
        })
        .catch((error) => reject(this._handleError(error)));
    });
  };

  // MISC METHODS
  newKey = (path) => {
    const db = getDatabase();
    const pathRef = ref(db, path);
    return push(pathRef).key;
  };

  // CHANGELOG METHODS

  /**
   * Updates the current version on the firebase realtime database
   * @param {String} newVersion String with the new version
   * @returns {Promise<Boolean|String>} Promise that resolves true when the version is stored successfully or rejects the error message
   */
  setCurrentVersion = (newVersion) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const changelogRef = ref(db, 'changelog/');

      set(changelogRef, {
        currentVersion: newVersion,
      }).then(
        () => resolve(true),
        (error) => reject(this._handleError(error)),
      );
    });
  };

  /**
   * Registers a new version and its description on the firebase realtime database
   * @param {String} version String with the new version
   * @param {Object} description Array with the descriptions of new functionalities
   * @returns {Promise<Boolean|String>} Promise that resolves true when the version is stored successfully or rejects the error message
   */
  registerNewVersion = ({ version, description }) => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const changelogRef = ref(db, 'changelog/');
      const newChangelogRef = push(changelogRef);

      set(newChangelogRef, {
        version: version,
        description: description,
      }).then(
        () => resolve(true),
        (error) => reject(this._handleError(error)),
      );
    });
  };

  /**
   * Returns a String with the current version of Ectools from firebase
   * @returns {Promise<String>} Promise that resolves the current version or rejects the error message
   */
  getCurrentVersion = () => {
    return new Promise(async (resolve, reject) => {
      const db = getDatabase();
      const currentVersionRef = ref(db, 'changelog/currentVersion');

      try {
        const snapshot = await get(currentVersionRef);
        const databaseValue = snapshot.val();
        resolve(databaseValue);
      } catch (error) {
        reject(this._handleError(error));
      }
    });
  };

  /**
   * Returns an Object with the given version registered on the firebase database
   * @param {String} version String with the version to be retrieved
   * @returns {Promise<Object|String>} Promise that resolves all versions or rejects the error message
   */
  getVersion = (version) => {
    // Firebase RealTime Database doesn't accept '.' so they are replaced by '-'
    const normalizedVersion = version.replace(/\./g, '-');

    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const versionRef = ref(db, `changelog/${normalizedVersion}`);

      get(versionRef)
        .then((snapshot) => {
          const databaseValue = snapshot.val();
          resolve(databaseValue);
        })
        .catch((error) => {
          reject(this._handleError(error));
        });
    });
  };

  /**
   * Returns an Object with all the versions registered on the firebase database
   * @returns {Promise<Object|String>} Promise that resolves all versions or rejects the error message
   */
  getAllVersions = () => {
    return new Promise((resolve, reject) => {
      const db = getDatabase();
      const changelogRef = ref(db, 'changelog/');

      get(changelogRef)
        .then(async (snapshot) => {
          const databaseValue = await snapshot.val();
          delete databaseValue.currentVersion;
          resolve(databaseValue);
        })
        .catch((error) => {
          reject(this._handleError(error));
        });
    });
  };

  // ERROR HANDLING

  /**
   * Get the error message and code from the error object and return it
   * @param {object} error Object with the error thrown
   * @param {Boolean} debug Boolean to specify type of return, simple string or array including the code
   * @returns {(object|String)} Returns the message string of the error or an array with the message and the code
   */
  _handleError(error, debug = false) {
    const errorCode = error.code;
    const errorMessage = error.message;
    return debug ? [errorMessage, errorCode] : errorMessage;
  }
}

let _fireBaseBackend = null;

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = (config) => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config);
  }
  return _fireBaseBackend;
};

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
  return _fireBaseBackend;
};

export { initFirebaseBackend, getFirebaseBackend };
