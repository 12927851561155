import i18n from 'i18n';

const accountModules = [
  { value: 'tasks', label: i18n.t('Tasks') },
  {
    value: 'content',
    label: i18n.t('Content'),
  },
  {
    value: 'courses',
    label: i18n.t('Courses'),
  },
];

export { accountModules };
